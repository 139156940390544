import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localesDe from '@angular/common/locales/de';
import localesDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ErrorSnackbarModule } from './components/error-snackbar/error-snackbar.module';
import { FooterModule } from './components/footer/footer.module';
import { MenuModule } from './components/menu/menu.module';
import { SearchBoxModule } from './components/search-box/search-box.module';
import { TopbarModule } from './components/topbar/topbar.module';
import { HomeModule } from './pages/home/home.module';
import { PurchaseModule } from './pages/purchase/purchase.module';
import { TaskDetailModule } from './pages/tasks/task-detail/task-detail.module';
import { TasksModule } from './pages/tasks/tasks.module';
import { PanelsModule } from './components/panels/panels.module';
import { ProgressBarModule } from 'primeng/progressbar';
// import { RouteStrategy } from './route-strategy';

registerLocaleData(localesDe, 'de', localesDeExtra);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    MenuModule,
    SearchBoxModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule,
    HomeModule,
    ErrorSnackbarModule,
    TopbarModule,
    TasksModule,
    TaskDetailModule,
    PurchaseModule,
    FooterModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    PanelsModule,
    ProgressBarModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: navigator.language },
    // { provide: RouteReuseStrategy, useClass: RouteStrategy }
  ],
})
export class AppModule { }
