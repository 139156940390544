import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrderParser } from 'projects/api/src/api';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'c-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => InputComponent),
    multi: true     
  }]
})
export class InputComponent implements ControlValueAccessor, OnInit {

  @Input() type: 'file' | 'orderparser' = 'file' 
  @Input() disabled = false

  orderParsers: OrderParser[] = []
  selectedOrderParser: OrderParser | null = null

  constructor(
    private orderService: OrderService,
  ) { }

  async ngOnInit() {
    const [orderParsers] = await this.orderService.getOrderParsers()

    if (orderParsers) {
      this.orderParsers = orderParsers.items.filter(i => !i.assemblyTemplate) // tmp, dont get deprecated orderParsers
      this.selectedOrderParser = this.orderParsers[0]
    }
  }

  onChange: any = () => {}
  onTouch: any = () => {}
  val: any = null

  get value(): any {
    return this.val
  }

  set value(val: any){
    if (val !== undefined && this.val !== val) {
      this.val = val
      this.onChange(val)
      this.onTouch(val)
    }
  }

  writeValue(value: any){
    this.value = value
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  selectFile(files: FileList | null) {
    if (files) {
      this.value = files.item(0)
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
}
