import { Injectable } from '@angular/core';

export const orderStates = ['New', 'InProgress', 'Finished'] as const;
export type OrderState = typeof orderStates[number];

export const orderLineStates = ['WaitingForPrerequisites', 'NoOutputsDefined', 'Reviewing', 'Downloaded', 'PrerequisitesFulfilled', 'WaitingForProcessing', 'Queued', 'Processing', 'WaitingForQC', 'Done', 'Rejected', 'Error', 'Locked', 'Deferred'] as const;
export type OrderLineState = typeof orderLineStates[number];

export const taskStates = ['New', 'InProgress', 'Done'] as const;
export type TaskState = typeof taskStates[number];

export const subtaskStates = ['NotRelevant', 'ViaContract', 'Unassigned', 'New', 'InProgress', 'Done'] as const
export type SubtaskState = typeof subtaskStates[number];

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private _orderLineStateColors: {[state in OrderLineState]: string} = {
    Reviewing: '#94640b',
    WaitingForPrerequisites: '#ffae17',
    NoOutputsDefined: '#a83613',
    Rejected: '#f7673b',
    Error: '#d6481c',
    PrerequisitesFulfilled: '#9f6ed6',
    WaitingForProcessing: '#1f73a0',
    Queued: '#339ed8',
    Processing: '#8dd0ff',
    WaitingForQC: '#2cc59f',
    Done: '#57c21a',
    Downloaded: '#2f7408',
    Locked: '#666666',
    Deferred: '#ff0000'
  }

  public get orderLineStateColors() {
    return this._orderLineStateColors
  }

  private _orderStateColors: {[state in OrderState]: string} = {
    New: '#1f73a0',
    InProgress: '#8dd0ff',
    Finished: '#2f7408',
  }

  private _taskStateColors: {[state in TaskState]: string} = {
    New: '#1f73a0',
    InProgress: '#8dd0ff',
    Done: '#2f7408',
  }

  private _subtaskStateColors: {[state in SubtaskState]: string} = {
    NotRelevant: '#555555',
    Unassigned: '#d6481c',
    ViaContract: '#ffae17',
    New: '#1f73a0',
    InProgress: '#8dd0ff',
    Done: '#2f7408',
  }

  public get orderStateColors() {
    return this._orderStateColors
  }

  public get taskStateColors() {
    return this._taskStateColors
  }

  public get subtaskStateColors() {
    return this._subtaskStateColors
  }

  constructor() { }

}
