<div class="file" *ngIf="type === 'file'">
  <div class="ig ig-input-button">
    <input type="text" readonly [value]="val?.name" (click)="fileInput.click()"  [disabled]="disabled" />
    <button (click)="fileInput.click()" [disabled]="disabled"><i class="pi pi-upload"></i></button>
  </div>
  <input hidden type="file" name="file" #fileInput (change)="selectFile(fileInput.files)" />
</div>
<div class="order-parser" *ngIf="type === 'orderparser'">
  <select [(ngModel)]="value" [disabled]="disabled">
    <option *ngFor="let orderParser of orderParsers" [ngValue]="orderParser">{{orderParser.name}}</option>
  </select>
</div>