import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { CommentModule } from '../../../components/comments/comment.module';
import { ErrorSnackbarModule } from '../../../components/error-snackbar/error-snackbar.module';
import { HeaderModule } from '../../../components/header/header.module';
import { TableModule } from '../../../components/table/table.module';
import { TaskDetailAttachmentComponent, TaskDetailComponent, TaskDetailSubtaskComponent } from './task-detail.component';
import { InputModule } from '../../../components/input/input.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { SubtaskAssignComponent } from './subtask-assign/subtask-assign.component';
import { SubtaskComponent } from './subtask/subtask.component';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { UserIconModule } from '../../../components/user-icon/user-icon.module';


@NgModule({
  declarations: [
    TaskDetailComponent,
    TaskDetailSubtaskComponent,
    TaskDetailAttachmentComponent,
    SubtaskAssignComponent,
    SubtaskComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    HeaderModule,
    CommentModule,
    ErrorSnackbarModule,
    SidebarModule,
    RouterModule,
    UserIconModule,
    DropdownModule,
    InputModule,
    PipesModule,
    ProgressBarModule,
    TooltipModule,
    MenuModule,
  ]
})
export class TaskDetailModule { }
