import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AsPipe } from './as.pipe';
import { AssetPreviewSetImagePipe } from './asset-preview-set-image.pipe';
import { FilterDeletedPipe } from './filter-deleted.pipe';
import { PastelColorPipe } from './pastel-color.pipe';
import { ProcessingTimePipe } from './processing-time.pipe';
import { SplitByUpperCasePipe } from './split-by-upper-case.pipe';
import { JobPipe } from './job.pipe';
import { SubtaskNamePipe } from './subtask-name.pipe';
import { ConditionPathToNamePipe } from './filter-path-to-name.pipe';
import { JobStateMapperPipe } from './job-state-mapper.pipe';
import { StepStateMapperPipe } from './step-state-mapper.pipe';
import { AssetVersionPipe } from './asset-version';
import { FPipe } from './f.pipe';

const pipes = [
  ProcessingTimePipe, 
  AsPipe, 
  PastelColorPipe, 
  FilterDeletedPipe, 
  AssetPreviewSetImagePipe, 
  SplitByUpperCasePipe, 
  SubtaskNamePipe, 
  JobPipe, 
  ConditionPathToNamePipe, 
  JobStateMapperPipe, 
  FPipe,
  StepStateMapperPipe, 
  AssetVersionPipe,
]

@NgModule({
  declarations: [
    ...pipes,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...pipes
  ]
})
export class PipesModule { }
