
<header *ngIf="!(authUser | async)">
  <div class="menu">
    <img class="logo" src="/assets/images/imageJet-logo-white.png">
    <ul>
      <li><a [class.active]="rootPage" [routerLink]="''">Explore</a></li>
      <li><a [routerLinkActive]="'active'" [routerLink]="'/agencies'">Agencies</a></li>
      <li><a [routerLinkActive]="'active'" [routerLink]="'/documentation'">Documentation</a></li>
      <li><a [routerLinkActive]="'active'" [routerLink]="'/pricing'">Pricing</a></li>
    </ul>
  </div>
  <div class="user-menu">
    <button class="primary" (click)="login()">Login</button>
    <button class="primary" (click)="register()">Register</button>
  </div>
</header>

<header *ngIf="(authUser | async) as user">
  <div class="menu">
    <img class="logo" src="/assets/images/imageJet-logo-white.png">
    <ul>
      <li><a [class.active]="rootPage" [routerLink]="''">Explore</a></li>
      <!-- <li><a [routerLinkActive]="'active'" [routerLink]="'/agencies'">Agencies</a></li> -->
      <li><a [routerLinkActive]="'active'" [routerLink]="'/docs'">Documentation</a></li>
      <!-- <li><a [routerLinkActive]="'active'" [routerLink]="'/pricing'">Pricing</a></li> -->
    </ul>
  </div>
  <div class="user-menu">
    <ul>
      <!-- <li>
        <a [routerLinkActive]="'active'" [routerLink]="'/orders'">
          <span>Orders</span>
          <span class="detail">1 Open</span>
        </a>
      </li>
      <li>
        <a [routerLinkActive]="'active'" [routerLink]="'/jobs'">
          <span>Jobs</span>
          <span class="detail">23 Running</span>
        </a>
      </li> -->
      <li>
        <a [routerLinkActive]="'active'" [routerLink]="'/dashboard'">
          <span *ngIf="userAgency | async as agency">{{ agency.displayName || agency.name }}</span>
          <span class="detail" *ngIf="(userAgencyStatistic | async) as uas">{{uas.orders.new + uas.orders.inProgress}} open order, {{uas.jobs.downloading + uas.jobs.running}} running jobs</span>
        </a>
      </li>
      <li class="no-margin">
        <p-menu appendTo="body" #menu [popup]="true" [model]="items"></p-menu>
        <a class="icon" (click)="menu.toggle($event)">
          <!-- <span>{{ user.displayName }}</span>
          <span class="detail" *ngIf="userAgency | async as agency">{{ agency.displayName }}</span> -->
          <i class="pi pi-ellipsis-v"></i>
        </a>
      </li>
      <li class="spacer"></li>
      <li>
        <a class="icon notification-icon" (click)="notifications.toggle($event)">
          <!-- <span>{{ user.displayName }}</span>
          <span class="detail" *ngIf="userAgency | async as agency">{{ agency.displayName }}</span> -->
          <i class="pi pi-bell"></i>
          <div class="progress-bar" *ngIf="uploadOverallProgress | async">
            <p-progressBar mode="indeterminate" [style]="{'height': '4px'}"></p-progressBar>
          </div>
        </a>
      </li>
      <li>
        <a class="icon" [routerLinkActive]="'active'" [routerLink]="'/profile'">
          <!-- <span>{{ user.displayName }}</span>
          <span class="detail" *ngIf="userAgency | async as agency">{{ agency.displayName }}</span> -->
          <i class="pi pi-user"></i>
        </a>
      </li>
    </ul>
  </div>
</header>

<p-overlayPanel #notifications appendTo="body" styleClass="notifications" (onShow)="initNotificationOverlay()">
  <ng-template pTemplate>
    <p-tabView>
      <p-tabPanel header="Notifications">
        Coming soon
      </p-tabPanel>
      <p-tabPanel header="Uploads" [selected]="true" *ngIf="(uploads | async) as up">
        <div *ngFor="let upload of up" class="notification" (click)="notifications.hide(); openUpload(upload)">
          <c-upload-progress [upload]="upload"></c-upload-progress>
        </div>
        <div class="notification-empty" *ngIf="up.length === 0">No recent uploads</div>
      </p-tabPanel>
  </p-tabView>
  </ng-template>
</p-overlayPanel>