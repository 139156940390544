<div class="loading" *ngIf="loading">
  <div class="image">
    <img src="/assets/images/imageJet-logo-white.png" />
  </div>
  <div class="text">
    Loading ...
  </div>
  <div class="progress">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
</div>
<div *ngIf="!loading">
  <app-topbar *ngIf="!noAgency" [style]="{'box-shadow': boxShadow, 'background-color': backgroundColor}"></app-topbar>
  <div class="content" [ngClass]="contentClass">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>