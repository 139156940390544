import { Pipe, PipeTransform } from '@angular/core';
import { AndCondition, AssemblingError, AssemblyTemplateReference, AssemblyTemplateReferenceAsset, AssemblyTemplateReferenceStepOutput, AssemblyTemplateSublayer, AssemblyTemplateSublayerAsset, AssemblyTemplateSublayerStepOutput, AssetHistoryEntry, AssetNotFoundAssemblingError, AssetVersionNotFoundAssemblingError, AssetVersionStateHistoryEntry, BlockedVariantSelectionAssemblingError, CameraNotFoundAssemblingError, CommentAssetHistoryEntry, CommentOrderLineHistoryEntry, Condition, ContainsCondition, CreateAssetVersionSubtask, CreateCameraSubtask, CreateVariantSetSubtask, CreateVariantValueSubtask, DateAfterCondition, DateBeforeCondition, DateIsCondition, DateIsNotCondition, EndsWithCondition, EqualsCondition, GeneratedPreview, InCondition, InvalidAssetAssemblingError, InvalidAssetTypeAssemblingError, InvalidAssetVersionNumberAssemblingError, InvalidConditionAssemblingError, InvalidDefinitionTypeAssemblingError, InvalidInputStringAssemblingError, InvalidMainFileAssemblingError, InvalidNumberOfInputsAssemblingError, ManualSubtask, MissingCameraAssemblingError, MissingSceneAssemblingError, NotContainsCondition, NotEqualsCondition, NotInCondition, OrCondition, OrderLineHistoryEntry, OrderLinePreview, ParsingOrderLineHistoryEntry, PathNotFoundAssemblingError, Preview, StartsWithCondition, StateOrderLineHistoryEntry, StepInput, StepInputAsset, StepInputFile, StepInputMap, StepInputString, StepInputTemplate, StepInputTemplateAsset, StepInputTemplateFile, StepInputTemplateMap, StepInputTemplateString, StepOutput, StepOutputFile, StepTemplate, StepTemplateAssembling, StepTemplateAssembling_Default_v1, StepTemplateCompositing, StepTemplatePrefab, StepTemplateRendering, Subtask, UploadPreview, VariantSetNotFoundAssemblingError, VariantValueNotFoundAssemblingError } from 'projects/api/src/api';
import { ConditionService } from '../services/condition.service';

@Pipe({
  name: 'as',
  pure: true,
})
export class AsPipe implements PipeTransform {

  constructor(
    private conditionService: ConditionService
  ) {}

  transform(value: AssemblingError, ...args: ['AssemblingError', 'AssetNotFound']): AssetNotFoundAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'AssetVersionNotFound']): AssetVersionNotFoundAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'CameraNotFound']): CameraNotFoundAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidAssetType']): InvalidAssetTypeAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidAssetVersionNumber']): InvalidAssetVersionNumberAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidCondition']): InvalidConditionAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidDefinitionType']): InvalidDefinitionTypeAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidMainFile']): InvalidMainFileAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'MissingCamera']): MissingCameraAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'MissingScene']): MissingSceneAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'PathNotFound']): PathNotFoundAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'VariantSetNotFound']): VariantSetNotFoundAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'VariantValueNotFound']): VariantValueNotFoundAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'BlockedVariantSelection']): BlockedVariantSelectionAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidNumberOfInputs']): InvalidNumberOfInputsAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidAsset']): InvalidAssetAssemblingError
  transform(value: AssemblingError, ...args: ['AssemblingError', 'InvalidInputString']): InvalidInputStringAssemblingError

  transform(value: OrderLineHistoryEntry, ...args: ['OrderLineHistoryEntry', 'CommentOrderLineHistoryEntry']): CommentOrderLineHistoryEntry
  transform(value: OrderLineHistoryEntry, ...args: ['OrderLineHistoryEntry', 'ParsingOrderLineHistoryEntry']): ParsingOrderLineHistoryEntry
  transform(value: OrderLineHistoryEntry, ...args: ['OrderLineHistoryEntry', 'StateOrderLineHistoryEntry']): StateOrderLineHistoryEntry

  transform(value: AssetHistoryEntry, ...args: ['AssetHistoryEntry', 'CommentAssetHistoryEntry']): CommentAssetHistoryEntry
  transform(value: AssetHistoryEntry, ...args: ['AssetHistoryEntry', 'AssetVersionStateHistoryEntry']): AssetVersionStateHistoryEntry

  transform(value: Condition, ...args: ['Condition', 'Or']): OrCondition
  transform(value: Condition, ...args: ['Condition', 'And']): AndCondition
  transform(value: Condition, ...args: ['Condition', 'Contains']): ContainsCondition
  transform(value: Condition, ...args: ['Condition', 'NotContains']): NotContainsCondition
  transform(value: Condition, ...args: ['Condition', 'Equals']): EqualsCondition
  transform(value: Condition, ...args: ['Condition', 'NotEquals']): NotEqualsCondition
  transform(value: Condition, ...args: ['Condition', 'StartsWith']): StartsWithCondition
  transform(value: Condition, ...args: ['Condition', 'EndsWith']): EndsWithCondition
  transform(value: Condition, ...args: ['Condition', 'In']): InCondition
  transform(value: Condition, ...args: ['Condition', 'NotIn']): NotInCondition
  transform(value: Condition, ...args: ['Condition', 'DateIs']): DateIsCondition
  transform(value: Condition, ...args: ['Condition', 'DateIsNot']): DateIsNotCondition
  transform(value: Condition, ...args: ['Condition', 'DateBefore']): DateBeforeCondition
  transform(value: Condition, ...args: ['Condition', 'DateAfter']): DateAfterCondition
  transform(value: Condition, ...args: ['Condition', 'WithStringValue']): ContainsCondition | NotContainsCondition | EqualsCondition | NotEqualsCondition | StartsWithCondition | EndsWithCondition
  transform(value: Condition, ...args: ['Condition', 'WithStringValues']): InCondition | NotInCondition
  transform(value: Condition, ...args: ['Condition', 'WithDateValue']): DateIsCondition | DateIsNotCondition | DateBeforeCondition | DateAfterCondition
  transform(value: Condition, ...args: ['Condition', 'WithSubConditions']): OrCondition | AndCondition  
  transform(value: Condition, ...args: ['Condition', 'WithPath']): Condition & {path: string}

  transform(value: Subtask, ...args: ['Subtask', 'CreateAssetVersion']): CreateAssetVersionSubtask
  transform(value: Subtask, ...args: ['Subtask', 'CreateVariantSet']): CreateVariantSetSubtask
  transform(value: Subtask, ...args: ['Subtask', 'CreateVariantValue']): CreateVariantValueSubtask
  transform(value: Subtask, ...args: ['Subtask', 'CreateCamera']): CreateCameraSubtask
  transform(value: Subtask, ...args: ['Subtask', 'Manual']): ManualSubtask
  
  transform(value: StepTemplate, ...args: ['StepTemplate', 'Assembling']): StepTemplateAssembling
  transform(value: StepTemplate, ...args: ['StepTemplate', 'Assembling_Default_v1']): StepTemplateAssembling_Default_v1
  transform(value: StepTemplate, ...args: ['StepTemplate', 'Rendering']): StepTemplateRendering
  transform(value: StepTemplate, ...args: ['StepTemplate', 'Compositing']): StepTemplateCompositing
  transform(value: StepTemplate, ...args: ['StepTemplate', 'Prefab']): StepTemplatePrefab
  
  transform(value: StepInputTemplate, ...args: ['StepInputTemplate', 'Asset']): StepInputTemplateAsset
  transform(value: StepInputTemplate, ...args: ['StepInputTemplate', 'String']): StepInputTemplateString
  transform(value: StepInputTemplate, ...args: ['StepInputTemplate', 'Map']): StepInputTemplateMap
  transform(value: StepInputTemplate, ...args: ['StepInputTemplate', 'File']): StepInputTemplateFile

  transform(value: StepInput, ...args: ['StepInput', 'Asset']): StepInputAsset
  transform(value: StepInput, ...args: ['StepInput', 'String']): StepInputString
  transform(value: StepInput, ...args: ['StepInput', 'Map']): StepInputMap
  transform(value: StepInput, ...args: ['StepInput', 'File']): StepInputFile

  transform(value: StepOutput, ...args: ['StepOutput', 'File']): StepOutputFile
  
  transform(value: AssemblyTemplateReference, ...args: ['AssemblyTemplateReference', 'Asset']): AssemblyTemplateReferenceAsset
  transform(value: AssemblyTemplateReference, ...args: ['AssemblyTemplateReference', 'StepOutput']): AssemblyTemplateReferenceStepOutput
  transform(value: AssemblyTemplateSublayer, ...args: ['AssemblyTemplateSublayer', 'Asset']): AssemblyTemplateSublayerAsset
  transform(value: AssemblyTemplateSublayer, ...args: ['AssemblyTemplateSublayer', 'StepOutput']): AssemblyTemplateSublayerStepOutput
  
  transform(value: Preview, ...args: ['Preview', 'GeneratedPreview']): GeneratedPreview
  transform(value: Preview, ...args: ['Preview', 'UploadPreview']): UploadPreview
  transform(value: Preview, ...args: ['Preview', 'OrderLinePreview']): OrderLinePreview

  transform(value?: any, ...args: [string, string]): unknown {
    if (!value) return null

    // if (args[0] === 'AssemblingError') return value.type === args[1] ? value : null;
    // if (args[0] === 'OrderLineHistoryEntry') return value.type === args[1] ? value : null;
    // if (args[0] === 'AssetHistoryEntry') return value.type === args[1] ? value : null;
    // if (args[0] === 'Subtask') return value.type === args[1] ? value : null;
    // if (args[0] === 'StepInputTemplate') return value.type === args[1] ? value : null;
    // if (args[0] === 'StepInput') return value.type === args[1] ? value : null;
    // if (args[0] === 'StepOutput') return value.type === args[1] ? value : null;
    // if (args[0] === 'AssemblyTemplateReference') return value.type === args[1] ? value : null;
    // if (args[0] === 'AssemblyTemplateSublayer') return value.type === args[1] ? value : null;
    // if (args[0] === 'Preview') return value.type === args[1] ? value : null;

    if (args[0] === 'StepTemplate') {
      if (args[1] === 'Assembling') {
        return (value as StepTemplate).group === 'Assembling' ? value : null;
      }

      // return value.type === args[1] ? value : null;
    }
    
    if (args[0] === 'Condition') {
      if (args[1] === 'WithStringValue') return this.conditionService.isConditionWithStringValue(value) ? value : null
      if (args[1] === 'WithStringValues') return this.conditionService.isConditionWithStringValues(value) ? value : null
      if (args[1] === 'WithDateValue') return this.conditionService.isConditionWithDateValue(value) ? value : null
      if (args[1] === 'WithSubConditions') return this.conditionService.isConditionWithSubConditions(value) ? value : null
      if (args[1] === 'WithPath') return this.conditionService.isConditionWithPath(value) ? value : null
      
      // return value.type === args[1] ? value : null
    }

    return value.type === args[1] ? value : null;
    // return null
  }

}
