import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService  {

  constructor() { }

  public download(signedURL: string, name?: string) {
    const link = document.createElement('a');

    link.href = signedURL;
    if (name && name.length > 0) {
      link.download = name;
    }
    link.click();
    link.remove(); 
  }

}
