<span *ngIf="viewMode === 'Text'" class="text">
  <span *ngIf="condition; else noCondition">
    <span *ngIf="condition | as:'Condition':'WithSubConditions' as condition">
      <span *ngIf="level > 0" [style.color]="parenthesisColor">{{'('}}</span>
      <span *ngFor="let c of condition.conditions; index as i">
        <span *ngIf="i > 0" [style.color]="keywordColor"><strong> {{ condition.type | lowercase }} </strong></span>
        <c-condition [condition]="c" [level]="level + 1"></c-condition>
      </span>
      <span *ngIf="level > 0" [style.color]="parenthesisColor">{{')'}}</span>
    </span>
    <span *ngIf="condition | as:'Condition':'WithStringValue' as condition">
      <span>{{condition.path | conditionPathToName}}</span>
      <span [style.color]="opColor"><strong> {{condition.type | lowercase}} </strong></span>
      <span [style.color]="valueColor">"{{condition.value}}"</span>
    </span>
    <span *ngIf="condition | as:'Condition':'WithStringValues' as condition">
      <span>{{condition.path | conditionPathToName}}</span>
      <span [style.color]="opColor"><strong> {{condition.type | lowercase}} </strong></span>
      <span [style.color]="parenthesisColor">[</span>
      <span *ngFor="let value of condition.values; index as i">
        <span *ngIf="i > 0">, </span>
        <span [style.color]="valueColor">"{{value}}"</span>
      </span>
      <span [style.color]="parenthesisColor">]</span>
    </span>
    <span *ngIf="condition | as:'Condition':'WithDateValue' as condition">
      <span>{{condition.path | conditionPathToName}}</span>
      <span [style.color]="opColor"><strong> {{condition.type | lowercase}} </strong></span>
      <span [style.color]="valueColor">"{{condition.value | date: 'medium'}}"</span>
    </span>  
  </span>
  <ng-template #noCondition>No Condition</ng-template>
</span>

<div *ngIf="viewMode === 'Editor'">

  <div *ngIf="condition; else noConditionEditor">
    <div *ngIf="condition! | as:'Condition':'WithSubConditions' as condition" class="with-subconditions" [style.border-color]="parenthesisColor">
      <div [style.background-color]="parenthesisColor" class="header">{{condition.type}}</div>
      <div>
        <div class="subconditions">
          <div class="subcondition" *ngFor="let c of condition.conditions; let index = index" [ngClass]="c.type | lowercase" [style.border-color]="parenthesisColor">
            <c-condition  viewMode="Editor" [(condition)]="condition.conditions[index]" [level]="level + 1"></c-condition>
            <button><i class="pi pi-times" (click)="deleteSubcondition(index)"></i></button>
          </div>
        </div>
        <div class="add">
          <button (click)="addMenu.toggle($event)" [style.border-color]="parenthesisColor">Add</button>
          <p-menu #addMenu [model]="addMenuItems" appendTo="body" [popup]="true"></p-menu>
        </div>
      </div>
    </div>
  
    <div *ngIf="condition | as:'Condition':'WithPath' as condition" class="condition">
      <div class="path" [style.width]="pathWidth" [style.border-color]="parenthesisColor">
        <select *ngIf="pathSettings" [ngModel]="condition.path" (ngModelChange)="pathChange($event)">
          <option *ngFor="let path of paths" [value]="path">{{ path | conditionPathToName }}</option>
        </select>
        <input *ngIf="!pathSettings" type="text" [(ngModel)]="condition.path" placeholder="Field" />
      </div>
  
      <div class="type" [style.border-color]="parenthesisColor">
        <select [ngModel]="condition.type" (ngModelChange)="conditionTypeChange($event)">
          <option *ngFor="let conditionType of conditionTypes" [value]="conditionType">{{ conditionType }}</option>
        </select>
      </div>
  
      <div class="value" [style.border-color]="parenthesisColor">
        <div *ngIf="settings?.suggestions as suggestions">
          <input *ngIf="condition | as:'Condition':'WithStringValue' as condition" 
            type="text" 
            [ngModel]="condition.value" 
            (ngModelChange)="valueChange($event)" 
            placeholder="Value"
          />
          <p-multiSelect *ngIf="condition | as:'Condition':'WithStringValues' as condition"
            [options]="suggestions"
            [ngModel]="condition.values"
            (ngModelChange)="valuesChange($event)"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
          ></p-multiSelect>
        </div>
    
        <div *ngIf="!settings?.suggestions">
          <input *ngIf="condition | as:'Condition':'WithStringValue' as condition" 
            type="text" 
            [ngModel]="condition.value" 
            (ngModelChange)="valueChange($event)" 
            placeholder="Value"
          />
          <p-chips *ngIf="condition | as:'Condition':'WithStringValues' as condition"
            [ngModel]="condition.values"
            (ngModelChange)="valuesChange($event)"
            [addOnBlur]="true"
            appendTo="body"
          ></p-chips>
          <input *ngIf="condition | as:'Condition':'WithDateValue' as condition" type="date" [(ngModel)]="condition.value" />
        </div>
      </div>
          
    </div>
  </div>

  <ng-template #noConditionEditor>
    <div class="add">
      <button (click)="addMenu.toggle($event)" [style.border-color]="parenthesisColor">Add</button>
      <p-menu #addMenu [model]="addMenuItems" appendTo="body" [popup]="true"></p-menu>
    </div>
  </ng-template>

</div>