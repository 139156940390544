
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'projects/web/src/app/auth/auth.service';

export const BASE_PATH = new InjectionToken<string>('basePath');

export interface Agency {
  _id: string;
  company?: string;
  name: string;
  displayName: string;
  nextJobNumber: string;
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface AssemblingUsedAsset {
  asset: string;
  assetName: string;
  assetVersion?: string;
  hash?: string;
}

export interface AssemblingError {
  type: ('AssetNotFound' | 'AssetVersionNotFound' | 'CameraNotFound' | 'InvalidAsset' | 'InvalidAssetType' | 'InvalidAssetVersionNumber' | 'InvalidCondition' | 'InvalidDefinitionType' | 'InvalidMainFile' | 'InvalidVariantSet' | 'InvalidVariantValue' | 'MissingCamera' | 'MissingScene' | 'PathNotFound' | 'VariantSetNotFound' | 'VariantValueNotFound' | 'InvalidEnvironmentVariableName' | 'InvalidEnvironmentVariableValue' | 'DuplicatedStepId' | 'InvalidFinalStep' | 'InvalidNumberOfInputs' | 'StepOutputNotFound' | 'InvalidInputString' | 'BlockedVariantSelection' | 'InvalidInputCondition');
  resolvedAt?: string;
  resolvedBy?: string;
  hash?: string;
}

export interface StepInput {
  name: string;
  title: string;
  type: ('String' | 'Asset' | 'Map' | 'Switch' | 'File');
  assemblingErrors: string[];
  assemblingUsedAssets: string[];
}

export interface StepInputString extends StepInput {
  type: ('String');
  values: string[];
  options?: {[key: string]: string};
}

export interface StepInputMap extends StepInput {
  type: ('Map');
  values: {[key: string]: string};
}

export interface StepInputAsset extends StepInput {
  type: ('Asset');
  assetVersions: string[];
}

export interface StepInputFileFile {
  step: string;
  output: string;
  path: string;
  stepType: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  hash: string;
}

export interface StepInputFile extends StepInput {
  type: ('File');
  fileType: ('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp');
  files: StepInputFileFile[];
}

export interface StepOutput {
  type: ('File');
  name: string;
  title: string;
  assemblingErrors: string[];
}

export interface StepOutputFile extends StepOutput {
  type: ('File');
  fileType: ('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp');
  path: string;
  mimeType: string;
  extension: string;
}

export interface Step {
  id: string;
  type: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  title: string;
  subtitle: string;
  hash?: string;
  assemblingErrors: string[];
  assemblingUsedAssets: string[];
  inputs: StepInput[];
  outputs: StepOutput[];
}

export interface Assembling {
  steps: Step[];
  finalStep: string;
  finalStepOutput: string;
  finalStepThumbnail?: string;
  finalStepOutputThumbnail?: string;
  usedAssets: AssemblingUsedAsset[];
  errors: AssemblingError[];
}

export interface InvalidFinalStepAssemblingError extends AssemblingError {
  type: ('InvalidFinalStep');
  id?: string;
  name?: string;
}

export interface File {
  _id: string;
  type: ('AssetVersionFile' | 'OrderUploadFile' | 'JobOutputFile' | 'PreviewFile' | 'TaskAttachmentFile');
  path: string;
  folder: string;
  mimeType: string;
  md5: string;
  length: number;
  fileName?: string;
  state: ('Uploading' | 'Error' | 'Finished');
  finishedAt: string;
  errorAt: string;
  agency: string;
}

export interface AssetVersionFile extends File {
  type: ('AssetVersionFile');
  asset: string;
  assetVersion: string;
  visibility: ('DevOnly' | 'PublishedOnly' | 'DevAndPublished');
  usd: boolean;
  linkedTo?: string;
  replacedPaths?: string[];
}

export interface AbstractUser {
  type: ('User' | 'ServiceUser');
  _id: string;
  displayName: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface Role {
  type: ('SystemRole' | 'CustomRole');
  _id: string;
  name: string;
  description: string;
  rights: string[];
}

export interface SystemRole extends Role {
  type: ('SystemRole');
  key: ('Owner' | 'AssetCreator');
}

export interface CustomRole extends Role {
  type: ('CustomRole');
  agency: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface User extends AbstractUser {
  type: ('User');
}

export interface UserWithDetails extends User {
  email: string;
  roles: Role[];
}

export interface AssetVersionStateHistory {
  state: ('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete');
  date: string;
  user: string;
  user$?: User;
}

export interface Tag {
  value: string;
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface AssetVersionsStatistic {
  count: number;
  calculatedAt: string;
  empty: number;
  uploading: number;
  analyzing: number;
  error: number;
  finished: number;
  submitted: number;
  rejected: number;
  publishing: number;
  published: number;
  obsolete: number;
}

export interface AssetLatestDevAssetVersion {
  _id: string;
  version: number;
}

export interface AssetLatestAssetVersion {
  _id: string;
  version: number;
  publishedAt: string;
  preview?: string;
}

export interface AssetPreviewSetPreviews {
  _id: string;
  title: string;
  thumbnailUrl: string;
  url: string;
}

export interface AssetPreviewSet {
  _id: string;
  url: string;
  thumbnailUrl: string;
  animationUrl: string;
  previews: AssetPreviewSetPreviews[];
}

export interface AssetPublishedAssetVersions {
  _id: string;
  version: number;
  publishedAt: string;
  preview?: string;
}

export interface Asset {
  _id: string;
  name: string;
  displayName: string;
  assetType: ('material' | 'object' | 'scene' | 'prefab');
  previewSet?: AssetPreviewSet;
  totalVersions: number;
  totalPublishedVersions: number;
  latestVersion?: number;
  assetVersionsStatistic?: AssetVersionsStatistic;
  latestAssetVersion?: string;
  latestAssetVersion$?: AssetLatestAssetVersion;
  latestDevAssetVersion?: string;
  latestDevAssetVersion$?: AssetLatestDevAssetVersion;
  publishedAssetVersions: AssetPublishedAssetVersions[];
  agency: string;
  agency$?: Agency;
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  updatedAt?: string;
  updatedBy?: string;
  deletedAt?: string;
  tags: Tag[];
}

export interface AssetVersionAgency$ {
  _id: string;
  name: string;
  displayName: string;
}

export interface AssetVersionAsset$ {
  _id: string;
  name: string;
  displayName: string;
  assetType: ('material' | 'object' | 'scene' | 'prefab');
}

export interface AssetVersionPreviewSetPreviews {
  _id: string;
  title: string;
  thumbnailUrl: string;
  url: string;
}

export interface AssetVersionPreviewSet {
  _id: string;
  url: string;
  thumbnailUrl: string;
  animationUrl: string;
  previews: AssetVersionPreviewSetPreviews[];
}

export interface AssetVersionBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetVersion {
  type: ('MaterialAssetVersion' | 'ObjectAssetVersion' | 'SceneAssetVersion' | 'PrefabAssetVersion');
  _id: string;
  version: number;
  latestDev: boolean;
  latest: boolean;
  agency: string;
  agency$?: AssetVersionAgency$;
  asset: string;
  asset$?: AssetVersionAsset$;
  devMd5: string;
  publishedMd5: string;
  state: ('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete');
  dependencies: string[];
  devDependencies: string[];
  mainFile: string;
  mainPrimPath: string;
  mainFile$?: AssetVersionFile;
  additionalFiles: string[];
  additionalFiles$?: AssetVersionFile[];
  devFiles: string[];
  devFiles$?: AssetVersionFile[];
  devFilesPattern: string[];
  error?: string;
  usdErrors?: string[];
  previewSet?: AssetVersionPreviewSet;
  stateHistory: AssetVersionStateHistory[];
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  uploadedAt?: string;
  uploadedBy?: string;
  uploadedBy$?: User;
  publishedAt?: string;
  publishedBy?: string;
  publishedBy$?: User;
  deletedAt?: string;
  deletedBy?: string;
  deletedBy$?: User;
  finishedAt?: string;
  errorAt?: string;
  boundingBox?: AssetVersionBoundingBox;
}

export interface AssetVersionDependency {
  _id: string;
  assetVersion: string;
  dependentOn: string;
  md5: string;
  dev: boolean;
}

export interface VariantSelection {
  variantSet: string;
  variantValue: string;
  path: string[];
}

export interface BlockedVariantSelection {
  _id: string;
  assetVersion: string;
  variantSelections: VariantSelection[];
  reason?: string;
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface VariantSet {
  path: string[];
  name: string;
  values: string[];
  default: string;
  variantSelections: VariantSelection[];
}

export interface AssetVersionVariantSet extends VariantSet {
  type: ('Local' | 'Referenced');
  assetVersion: string;
}

export interface LocalAssetVersionVariantSet extends AssetVersionVariantSet {
  type: ('Local');
}

export interface ReferencedAssetVersionVariantSet extends AssetVersionVariantSet {
  type: ('Referenced');
  referencedPath: string[];
  referencedAssetVersion: string;
  referencedAssetVersion$?: AssetVersion;
}

export interface BlockedVariantSelectionAssemblingError extends AssemblingError {
  type: ('BlockedVariantSelection');
  blockedVariantSelection: BlockedVariantSelection;
  baseName: string;
  asset: string;
  assetVersionName: string;
  assetVersion: string;
}

export interface AssetNotFoundAssemblingError extends AssemblingError {
  type: ('AssetNotFound');
  baseName: string;
  path?: string[];
}

export interface AssetVersionNotFoundAssemblingError extends AssemblingError {
  type: ('AssetVersionNotFound');
  baseName: string;
  asset: string;
  assetVersionName?: string;
  path?: string[];
}

export interface InvalidAssetTypeAssemblingError extends AssemblingError {
  type: ('InvalidAssetType');
  baseName: string;
  asset: string;
  assetType: ('material' | 'object' | 'scene' | 'prefab');
  path?: string[];
}

export interface InvalidAssetVersionNumberAssemblingError extends AssemblingError {
  type: ('InvalidAssetVersionNumber');
  baseName: string;
  asset: string;
  assetVersionName: string;
  path?: string[];
}

export interface Condition {
  type: ('And' | 'Or' | 'In' | 'NotIn' | 'StartsWith' | 'EndsWith' | 'Equals' | 'NotEquals' | 'Contains' | 'NotContains' | 'DateIs' | 'DateIsNot' | 'DateBefore' | 'DateAfter');
}

export interface InvalidConditionAssemblingError extends AssemblingError {
  type: ('InvalidCondition');
  condition: Condition;
  path: string[];
}

export interface InvalidDefinitionTypeAssemblingError extends AssemblingError {
  type: ('InvalidDefinitionType');
  path: string[];
  definitionType: string;
}

export interface InvalidMainFileAssemblingError extends AssemblingError {
  type: ('InvalidMainFile');
  baseName: string;
  asset: string;
  assetVersionName: string;
  assetVersion: string;
  mainFile: string;
  path?: string[];
}

export interface StepOutputNotFoundAssemblingError extends AssemblingError {
  type: ('StepOutputNotFound');
  step: string;
  output: string;
}

export interface VariantSetNotFoundAssemblingError extends AssemblingError {
  type: ('VariantSetNotFound');
  baseName: string;
  asset?: string;
  assetVersionName: string;
  assetVersion?: string;
  variantSet: string;
  path?: string[];
}

export interface VariantValueNotFoundAssemblingError extends AssemblingError {
  type: ('VariantValueNotFound');
  baseName: string;
  asset?: string;
  assetVersionName: string;
  assetVersion?: string;
  variantSet: string;
  variantValue: string;
  path?: string[];
}

export interface AssemblyReference {
  type: ('Asset' | 'StepOutput');
  path: string[];
  fullName: string;
}

export interface AssemblyReferenceAsset extends AssemblyReference {
  type: ('Asset');
  mainFile: string;
  asset: string;
  assetVersion: string;
}

export interface AssemblyReferenceStepOutput extends AssemblyReference {
  type: ('StepOutput');
  step: string;
  output: string;
}

export interface AssemblyVariant {
  asset: string;
  assetVersion: string;
  variantSet: string;
  variantValue: string;
  fullName: string;
}

export interface AssemblyDefinition {
  name: string;
  type: ('over' | 'xform' | 'def');
  reference?: AssemblyReference;
  variants?: AssemblyVariant[];
  definitions?: AssemblyDefinition[];
}

export interface AssemblySublayer {
  type: ('Asset' | 'StepOutput');
  fullName: string;
}

export interface AssemblySublayerAsset extends AssemblySublayer {
  type: ('Asset');
  mainFile: string;
  asset: string;
  assetVersion: string;
}

export interface AssemblySublayerStepOutput extends AssemblySublayer {
  type: ('StepOutput');
  step: string;
  output: string;
}

export interface Assembly {
  sublayers: AssemblySublayer[];
  definitions: AssemblyDefinition[];
}

export interface AssemblyTemplateReference {
  type: ('Asset' | 'StepOutput');
}

export interface AssemblyTemplateReferenceAsset extends AssemblyTemplateReference {
  type: ('Asset');
  asset: string;
  assetType: ('material' | 'object' | 'scene' | 'prefab');
  path?: string;
}

export interface AssemblyTemplateReferenceStepOutput extends AssemblyTemplateReference {
  type: ('StepOutput');
  step: string;
  output: string;
  path?: string;
}

export interface AssemblyTemplateSublayerCondition {
  
}

export interface AssemblyTemplateSublayer {
  type: ('Asset' | 'StepOutput');
  condition?: AssemblyTemplateSublayerCondition;
}

export interface AssemblyTemplateSublayerAsset extends AssemblyTemplateSublayer {
  type: ('Asset');
  asset: string;
}

export interface AssemblyTemplateSublayerStepOutput extends AssemblyTemplateSublayer {
  type: ('StepOutput');
  step: string;
  output: string;
}

export interface InvalidNumberOfInputsAssemblingError extends AssemblingError {
  type: ('InvalidNumberOfInputs');
  minValues: number;
  maxValues: number;
  values: number;
}

export interface InvalidInputStringAssemblingError extends AssemblingError {
  type: ('InvalidInputString');
  value: string;
}

export interface StepInputTemplate {
  name: string;
  type: ('String' | 'Asset' | 'Map' | 'Switch' | 'File');
  title: string;
  minValues: number;
  maxValues: number;
  condition?: Condition;
}

export interface StepInputTemplateString extends StepInputTemplate {
  type: ('String');
  values: string[];
  regex?: string;
  options: {[key: string]: string};
}

export interface StepInputTemplateMap extends StepInputTemplate {
  type: ('Map');
  values: {[key: string]: string};
}

export interface StepInputTemplateAsset extends StepInputTemplate {
  type: ('Asset');
  assets: string[];
}

export interface StepInputTemplateFile extends StepInputTemplate {
  type: ('File');
  fileType: ('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp');
  allowedFileTypes: ('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp')[];
  files: string[];
}

export interface StepInputTemplateSwitch extends StepInputTemplate {
  type: ('Switch');
  selected: number;
  inputs: StepInputTemplate[];
}

export interface StepOutputTemplate {
  type: ('File');
  name: string;
  title: string;
  list: boolean;
  condition?: Condition;
}

export interface StepOutputTemplateFile extends StepOutputTemplate {
  type: ('File');
  fileType: ('Exr' | 'Jpeg' | 'Json' | 'Png' | 'Usd' | 'Webp');
  path?: string;
}

export interface StepAssembling extends Step {
  type: ('Assembling_Default_v1');
}

export interface StepAssembling_Default_v1 extends StepAssembling {
  type: ('Assembling_Default_v1');
  assembly: Assembly;
}

export interface StepBasic extends Step {
  type: ('Basic_Or_v1');
}

export interface StepBasic_Or_v1 extends StepBasic {
  type: ('Basic_Or_v1');
}

export interface StepCompositing extends Step {
  type: ('Compositing_Houdini_19_5_534_Hython_v1');
  group: ('Compositing');
}

export interface StepCompositing_Houdini_19_5_534_Hython_v1 extends StepCompositing {
  type: ('Compositing_Houdini_19_5_534_Hython_v1');
}

export interface StepPrefab extends Step {
  type: ('Prefab_Houdini_19_5_534_Hython_v1');
  group: ('Prefab');
  agency: string;
  asset: string;
  assetVersion: string;
  action: number;
}

export interface StepPrefab_Houdini_19_5_534_Hython_v1 extends StepPrefab {
  type: ('Prefab_Houdini_19_5_534_Hython_v1');
}

export interface StepRendering extends Step {
  type: ('Rendering_Houdini_19_5_534_Arnold_v1');
  group: ('Rendering');
}

export interface StepRendering_Houdini_19_5_534_Arnold_v1 extends StepRendering {
  type: ('Rendering_Houdini_19_5_534_Arnold_v1');
}

export interface StepUtils extends Step {
  type: ('Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
}

export interface StepUtils_ConvertImage_v1 extends StepUtils {
  type: ('Utils_ConvertImage_v1');
}

export interface StepUtils_DownloadFile_v1 extends StepUtils {
  type: ('Utils_DownloadFile_v1');
}

export interface StepUtils_UsdAnalyzer_v1 extends StepUtils {
  type: ('Utils_UsdAnalyzer_v1');
}

export interface DuplicatedStepIdAssemblingError extends AssemblingError {
  type: ('DuplicatedStepId');
  id: string;
}

export interface InvalidInputConditionAssemblingError extends AssemblingError {
  type: ('InvalidInputCondition');
  inputName: string;
}

export interface StepTemplate {
  id: string;
  type: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  group: ('Basic' | 'Prefab' | 'Assembling' | 'Rendering' | 'Compositing' | 'Utils');
  title: string;
  subtitle: string;
  x: number;
  y: number;
  width: number;
  height: number;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface AssemblingTemplate {
  steps: StepTemplate[];
  finalStepOutput?: string;
  finalStepOutputThumbnail?: string;
  values: {[key: string]: string};
  valuesFromOutput: {[key: string]: string};
}

export interface CameraNotFoundAssemblingError extends AssemblingError {
  type: ('CameraNotFound');
  baseName: string;
  asset: string;
  assetVersionName: string;
  assetVersion: string;
  camera: string;
}

export interface InvalidAssetAssemblingError extends AssemblingError {
  type: ('InvalidAsset');
  asset: string;
  path?: string[];
}

export interface InvalidEnvironmentVariableNameAssemblingError extends AssemblingError {
  type: ('InvalidEnvironmentVariableName');
  name: string;
}

export interface InvalidEnvironmentVariableValueAssemblingError extends AssemblingError {
  type: ('InvalidEnvironmentVariableValue');
  name: string;
  value: string;
}

export interface InvalidVariantSetAssemblingError extends AssemblingError {
  type: ('InvalidVariantSet');
  asset: string;
  assetVersion: string;
  variantSet: string;
  path?: string[];
}

export interface InvalidVariantValueAssemblingError extends AssemblingError {
  type: ('InvalidVariantValue');
  asset: string;
  variantSet: string;
  variantValue: string;
  path?: string[];
}

export interface MissingCameraAssemblingError extends AssemblingError {
  type: ('MissingCamera');
  baseName: string;
  asset: string;
  assetVersionName: string;
  assetVersion: string;
}

export interface MissingSceneAssemblingError extends AssemblingError {
  type: ('MissingScene');
}

export interface PathNotFoundAssemblingError extends AssemblingError {
  type: ('PathNotFound');
  baseName?: string;
  asset?: string;
  assetVersionName?: string;
  assetVersion?: string;
}

export interface AssemblyTemplateVariantCondition {
  
}

export interface AssemblyTemplateVariant {
  asset: string;
  variantSet: string;
  variantValue: string;
  condition?: AssemblyTemplateVariantCondition;
}

export interface AssemblyTemplateDefinitionCondition {
  
}

export interface AssemblyTemplateDefinition {
  name: string;
  condition?: AssemblyTemplateDefinitionCondition;
  type: ('over' | 'xform' | 'def');
  reference?: AssemblyTemplateReference;
  variants?: AssemblyTemplateVariant[];
  definitions?: AssemblyTemplateDefinition[];
}

export interface AssemblyTemplate {
  sublayers: AssemblyTemplateSublayer[];
  definitions: AssemblyTemplateDefinition[];
  variants: AssemblyTemplateVariant[];
}

export interface StepTemplateAssembling extends StepTemplate {
  type: ('Assembling_Default_v1');
  group: ('Assembling');
}

export interface StepTemplateAssembling_Default_v1 extends StepTemplateAssembling {
  type: ('Assembling_Default_v1');
  title: string;
  subtitle: string;
  version: string;
  assemblyTemplate: AssemblyTemplate;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface EqualsCondition extends Condition {
  type: ('Equals');
  path: string;
  value: string;
}

export interface StepTemplateBasic extends StepTemplate {
  type: ('Basic_Or_v1');
  group: ('Basic');
}

export interface StepTemplateBasic_Or_v1 extends StepTemplateBasic {
  type: ('Basic_Or_v1');
  title: string;
  subtitle: string;
  version: string;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface Command {
  name: string;
  capability: ('Rendering' | 'Compositing');
}

export interface Engine {
  _id: string;
  type: ('Houdini' | 'Arnold');
  version: string;
  commands: Command[];
}

export interface StepTemplateCompositing extends StepTemplate {
  type: ('Compositing_Houdini_19_5_534_Hython_v1');
  group: ('Compositing');
  engine: ('Houdini' | 'Arnold');
  engineVersion: string;
}

export interface StepTemplateCompositing_Houdini_19_5_534_Hython_v1 extends StepTemplateCompositing {
  type: ('Compositing_Houdini_19_5_534_Hython_v1');
  title: string;
  subtitle: string;
  version: string;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface StepTemplatePrefab extends StepTemplate {
  type: ('Prefab_Houdini_19_5_534_Hython_v1');
  group: ('Prefab');
  engine: ('Houdini' | 'Arnold');
  engineVersion: string;
  agency?: string;
  asset?: string;
  assetVersion?: string;
  action?: number;
}

export interface StepTemplatePrefab_Houdini_19_5_534_Hython_v1 extends StepTemplatePrefab {
  type: ('Prefab_Houdini_19_5_534_Hython_v1');
  title: string;
  subtitle: string;
  version: string;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface StepTemplateRendering extends StepTemplate {
  type: ('Rendering_Houdini_19_5_534_Arnold_v1');
  group: ('Rendering');
  engine: ('Houdini' | 'Arnold');
  engineVersion: string;
}

export interface StepTemplateRendering_Houdini_19_5_534_Arnold_v1 extends StepTemplateRendering {
  type: ('Rendering_Houdini_19_5_534_Arnold_v1');
  title: string;
  subtitle: string;
  version: string;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface StepHash {
  stepType: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  hash: string;
}

export interface StepExecution extends StepHash {
  renderer?: string;
  thread?: number;
  error?: string;
  startedAt?: string;
  finishedAt?: string;
  job?: string;
}

export interface StepCache extends StepExecution {
  _id: string;
  agency: string;
  grep: string;
  renderer: string;
  thread: number;
  createdAt?: string;
  lastUsage?: string;
  deletedAt?: string;
}

export interface StepTemplateUtils extends StepTemplate {
  type: ('Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  group: ('Utils');
}

export interface StepTemplateUtils_ConvertImage_v1 extends StepTemplateUtils {
  type: ('Utils_ConvertImage_v1');
  title: string;
  subtitle: string;
  version: string;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface StepTemplateUtils_DownloadFile_v1 extends StepTemplateUtils {
  type: ('Utils_DownloadFile_v1');
  title: string;
  subtitle: string;
  version: string;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface StepTemplateUtils_UsdAnalyzer_v1 extends StepTemplateUtils {
  type: ('Utils_UsdAnalyzer_v1');
  title: string;
  subtitle: string;
  version: string;
  inputs: StepInputTemplate[];
  outputs: StepOutputTemplate[];
}

export interface AssetVersionReference {
  _id: string;
  assetVersion: string;
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetHistoryEntry {
  type: ('CommentAssetHistoryEntry' | 'AssetVersionStateHistoryEntry');
  date: string;
  user?: string;
  user$?: User;
}

export interface AssetVersionStateHistoryEntry extends AssetHistoryEntry {
  type: ('AssetVersionStateHistoryEntry');
  asset: string;
  assetVersion: string;
  assetVersionNumber: number;
  state: ('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete');
}

export interface CommentAssetHistoryEntry extends AssetHistoryEntry {
  type: ('CommentAssetHistoryEntry');
  message: string;
}

export interface Material {
  path: string[];
}

export interface MaterialAssetVersion extends AssetVersion {
  type: ('MaterialAssetVersion');
  materials?: Material[];
}

export interface ObjectAssetVersion extends AssetVersion {
  type: ('ObjectAssetVersion');
}

export interface PrefabActionInput {
  type: ('AssetPrefabActionInput' | 'StringPrefabActionInput');
  name: string;
  list: boolean;
}

export interface AssetPrefabActionInput extends PrefabActionInput {
  type: ('AssetPrefabActionInput');
}

export interface StringPrefabActionInput extends PrefabActionInput {
  type: ('StringPrefabActionInput');
}

export interface PrefabActionOutput {
  type: ('UsdPrefabActionOutput');
  name: string;
  path: string;
}

export interface UsdPrefabActionOutput extends PrefabActionOutput {
  type: ('UsdPrefabActionOutput');
}

export interface PrefabAction {
  name: string;
  filePath: string;
  executionPath: string;
  engine: ('Houdini' | 'Arnold');
  version: string;
  inputs: PrefabActionInput[];
  outputs: PrefabActionOutput[];
}

export interface PrefabAssetVersion extends AssetVersion {
  type: ('PrefabAssetVersion');
  actions?: PrefabAction[];
}

export interface Preview {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  _id: string;
  title: string;
  state: ('Creating' | 'Finished' | 'Error' | 'Deleted');
  createdAt: string;
  createdBy: string;
  variantSelections: VariantSelection[];
  deletedAt?: string;
  deletedBy?: string;
  thumbnailUrl?: string;
  url?: string;
}

export interface OutputFormat {
  type: ('JpegImage' | 'WebpImage');
  mimeType: string;
  extension: string;
}

export interface OutputEnvironmentVariables {
  name: string;
  value: string;
}

export interface Output {
  _id: string;
  assembling: Assembling;
  sceneAsset?: string;
  sceneAssetVersion?: string;
  sceneMainFile?: string;
  camera?: string;
  format?: OutputFormat;
  assembly?: Assembly;
  assemblingUsedAssets?: AssemblingUsedAsset[];
  assemblingErrors?: AssemblingError[];
  environmentVariables?: OutputEnvironmentVariables[];
}

export interface GeneratedPreview extends Preview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  lastJob?: string;
  output?: Output;
  assembling: Assembling;
}

export interface OrderLinePreview extends Preview {
  type: ('OrderLinePreview');
  order: string;
  orderLine: string;
}

export interface PreviewFile extends File {
  type: ('PreviewFile');
  previewSet: string;
  preview?: string;
}

export interface JobOutputEnvironmentVariables {
  name: string;
  value: string;
}

export interface JobOutputStateHistory {
  state: ('Queued' | 'Running' | 'Success' | 'Error' | 'Canceled');
  date: string;
  user?: string;
  message?: string;
}

export interface JobOutput {
  type: ('Plain' | 'PreviewSet' | 'Preview' | 'OrderLine' | 'UsdAnalyzer');
  _id: string;
  assembling: Assembling;
  url?: string;
  thumbnailUrl?: string;
  outputFormat?: OutputFormat;
  environmentVariables?: JobOutputEnvironmentVariables[];
  state?: ('Queued' | 'Running' | 'Success' | 'Error' | 'Canceled');
  stateHistory?: JobOutputStateHistory[];
  code?: number;
  errorMessage?: string;
}

export interface PreviewSetJobOutput extends JobOutput {
  type: ('PreviewSet');
  preview: string;
}

export interface Thread {
  index: number;
  state: ('Idle' | 'Working');
  job: string;
  step: StepHash;
  startedAt: string;
}

export interface RendererStateHistory {
  state: ('Creating' | 'Running' | 'Updating' | 'ShuttingDown' | 'Stopped' | 'Deleting' | 'Deleted');
  date: string;
}

export interface RendererGpusProccesses {
  username: string;
  command: string;
  memoryUsage: number;
  pid: number;
}

export interface RendererGpus {
  index: number;
  name: string;
  uuid: string;
  temperature: number;
  utilization: number;
  memoryUsed: number;
  memoryTotal: number;
  proccesses: RendererGpusProccesses[];
}

export interface Renderer {
  _id: string;
  expectedState: ('Creating' | 'Running' | 'Updating' | 'ShuttingDown' | 'Stopped' | 'Deleting' | 'Deleted');
  expectedShutdownLevel?: number;
  state: ('Creating' | 'Running' | 'Updating' | 'ShuttingDown' | 'Stopped' | 'Deleting' | 'Deleted');
  stateHistory: RendererStateHistory[];
  hostname: string;
  maxThreads?: number;
  socketId?: string;
  connected?: boolean;
  disconnectedAt?: string;
  threads: Thread[];
  engines: Engine[];
  gpus: RendererGpus[];
  stepTypes: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  disabledStepTypes?: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  lastPing?: string;
  uptime?: number;
  startedAt?: string;
  expectedVersion: string;
  version?: string;
  expectedCommit: string;
  commit?: string;
}

export interface JobUsedAsset {
  asset: string;
  assetVersion: string;
  hash: string;
}

export interface JobStateHistory {
  state: ('Queued' | 'Running' | 'Success' | 'Error' | 'Canceled' | 'Deferred' | 'Paused');
  date: string;
  user?: string;
}

export interface Job {
  type: ('Plain' | 'PreviewSet' | 'Preview' | 'OrderLine' | 'UsdAnalyzer');
  _id: string;
  jobNumber?: string;
  state: ('Queued' | 'Running' | 'Success' | 'Error' | 'Canceled' | 'Deferred' | 'Paused');
  stateHistory: JobStateHistory[];
  message?: string;
  jobOutputs: JobOutput[];
  grep?: string;
  nextStep?: StepHash;
  executionPlan: StepExecution[];
  pauseAt?: StepHash;
  errorMessage?: string;
  agency: string;
  agency$?: Agency;
  createdBy: string;
  createdBy$?: User;
  createdAt: string;
  usedAssets: JobUsedAsset[];
  renderer?: string;
  renderer$?: Renderer;
  thread?: number;
  greppedAt?: string;
  startedAt?: string;
  finishedAt?: string;
  montageUrl?: string;
  montageThumbnailUrl?: string;
  montageAnimationUrl?: string;
}

export interface PreviewSetJob extends Job {
  type: ('PreviewSet');
  asset?: string;
  assetVersion?: string;
  previewSet?: string;
  jobOutputs: PreviewSetJobOutput[];
}

export interface PreviewSet {
  _id: string;
  asset: string;
  assetVersion: string;
  state: ('Creating' | 'Working' | 'Processable' | 'Processing' | 'Error' | 'Finished');
  montageUrl?: string;
  montageThumbnailUrl?: string;
  montageAnimationUrl?: string;
  previews: Preview[];
  lastJob?: string;
  lastJob$?: PreviewSetJob;
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  deletedAt?: string;
  deletedBy?: string;
  deletedBy$?: User;
  publishedAt?: string;
  publishedBy?: string;
  publishedBy$?: User;
}

export interface Camera {
  name: string;
  path: string[];
}

export interface SceneAssetVersion extends AssetVersion {
  type: ('SceneAssetVersion');
  cameras?: Camera[];
}

export interface OutputTemplateVariant {
  asset?: string;
  variantSet?: string;
  variantValue?: string;
  path?: string;
}

export interface OutputTemplateTarget {
  asset?: string;
  targetName?: string;
  targetAsset?: string;
  path?: string;
}

export interface OutputTemplateVariable {
  name?: string;
  value?: string;
}

export interface OutputTemplateEnvironmentVariable {
  name?: string;
  value?: string;
}

export interface OutputTemplate {
  scene?: string;
  camera?: string;
  variants?: OutputTemplateVariant[];
  targets?: OutputTemplateTarget[];
  variables?: OutputTemplateVariable[];
  environmentVariables?: OutputTemplateEnvironmentVariable[];
}

export interface PreviewTemplate {
  _id: string;
  name: string;
  assetType: string;
  variables: {[key: string]: string};
  cameras?: Camera[];
  variantSets?: VariantSet[];
  assemblingTemplate: AssemblingTemplate;
  assemblyTemplate?: AssemblyTemplate;
  outputTemplate?: OutputTemplate;
}

export interface UploadPreview extends Preview {
  type: ('UploadPreview');
  uploadUrl?: string;
}

export interface Target {
  path: string[];
  name: string;
}

export interface AssetVersionTarget extends Target {
  type: ('Local' | 'Referenced');
  assetVersion: string;
}

export interface LocalTarget extends AssetVersionTarget {
  type: ('Local');
}

export interface ReferencedTarget extends AssetVersionTarget {
  type: ('Referenced');
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface Comment {
  _id: string;
  type: ('AssetComment' | 'TaskComment' | 'OrderLineComment');
  message: string;
  user: string;
  user$?: User;
  createdAt: string;
  deletedAt?: string;
}

export interface AssetComment extends Comment {
  _id: string;
  type: ('AssetComment');
  asset: string;
  assetVersion?: string;
}

export interface OrderLineComment extends Comment {
  _id: string;
  type: ('OrderLineComment');
  orderLineIdentifier: string;
  orderLine: string;
  order: string;
}

export interface TaskComment extends Comment {
  _id: string;
  type: ('TaskComment');
  task: string;
}

export interface Company {
  _id: string;
  name: string;
}

export interface AndCondition extends Condition {
  type: ('And');
  conditions: Condition[];
}

export interface ContainsCondition extends Condition {
  type: ('Contains');
  path: string;
  value: string;
}

export interface DateAfterCondition extends Condition {
  type: ('DateAfter');
  path: string;
  value: string;
}

export interface DateBeforeCondition extends Condition {
  type: ('DateBefore');
  path: string;
  value: string;
}

export interface DateIsCondition extends Condition {
  type: ('DateIs');
  path: string;
  value: string;
}

export interface DateIsNotCondition extends Condition {
  type: ('DateIsNot');
  path: string;
  value: string;
}

export interface EndsWithCondition extends Condition {
  type: ('EndsWith');
  path: string;
  value: string;
}

export interface InCondition extends Condition {
  type: ('In');
  path: string;
  values: string[];
}

export interface NotContainsCondition extends Condition {
  type: ('NotContains');
  path: string;
  value: string;
}

export interface NotEqualsCondition extends Condition {
  type: ('NotEquals');
  path: string;
  value: string;
}

export interface NotInCondition extends Condition {
  type: ('NotIn');
  path: string;
  values: string[];
}

export interface OrCondition extends Condition {
  type: ('Or');
  conditions: Condition[];
}

export interface StartsWithCondition extends Condition {
  type: ('StartsWith');
  path: string;
  value: string;
}

export interface Contract {
  _id: string;
  agency: string;
  contractor: string;
  name: string;
  description: string;
  validTo: string;
  createdAt: string;
  createdBy: string;
  acceptedAt?: string;
  acceptedBy?: string;
  declinedAt?: string;
  declinedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface FileInfo {
  path: string;
  md5: string;
  length: number;
}

export interface JobOutputFile extends File {
  type: ('JobOutputFile');
  job: string;
  jobOutput: string;
}

export interface JobProgress {
  job: string;
  date: string;
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress?: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface JobResult {
  _id: string;
  job: string;
  code: number;
  state: ('Success' | 'Error' | 'Cancel');
}

export interface OrderLineJobOutput extends JobOutput {
  type: ('OrderLine');
  output: string;
}

export interface OrderLineJobOrder {
  _id: string;
  name: string;
}

export interface OrderLineJobOrderLine {
  _id: string;
  identifier: string;
  orderParsing?: string;
  orderUpload?: string;
}

export interface OrderLineJob extends Job {
  type: ('OrderLine');
  order: string;
  order$?: OrderLineJobOrder;
  orderLine: string;
  orderLine$?: OrderLineJobOrderLine;
  jobOutputs: OrderLineJobOutput[];
}

export interface PreviewJobOutput extends JobOutput {
  type: ('Preview');
  output?: string;
  sceneAsset?: string;
  sceneAssetVersion?: string;
  sceneMainFile?: string;
  camera?: string;
  assembly?: Assembly;
}

export interface PreviewJob extends Job {
  type: ('Preview');
  previewSet: string;
  previewSet$?: PreviewSet;
  preview: string;
  preview$?: Preview;
  jobOutputs: PreviewJobOutput[];
  usedAssets: JobUsedAsset[];
}

export interface UsdAnalyzerJobOutput extends JobOutput {
  type: ('UsdAnalyzer');
}

export interface UsdAnalyzerJob extends Job {
  type: ('UsdAnalyzer');
  asset: string;
  assetVersion: string;
  jobOutputs: UsdAnalyzerJobOutput[];
}

export interface LogEntry {
  job: string;
  output: string;
  line: number;
  date: string;
  stream: ('stdout' | 'stderr');
  step: string;
  log: string;
}

export interface OrderLineHistoryEntry {
  type: ('CommentOrderLineHistoryEntry' | 'ParsingOrderLineHistoryEntry' | 'StateOrderLineHistoryEntry');
  date: string;
  user?: string;
  user$?: User;
}

export interface CommentOrderLineHistoryEntry extends OrderLineHistoryEntry {
  type: ('CommentOrderLineHistoryEntry');
  message: string;
}

export interface ParsingOrderLineHistoryEntry extends OrderLineHistoryEntry {
  type: ('ParsingOrderLineHistoryEntry');
  orderUpload: string;
  orderParsing: string;
}

export interface OrderLinesStatistic {
  count: number;
  calculatedAt: string;
  waitingForPrerequisites: number;
  noOutputsDefined: number;
  reviewing: number;
  prerequisitesFulfilled: number;
  waitingForProcessing: number;
  queued: number;
  processing: number;
  waitingForQC: number;
  done: number;
  rejected: number;
  error: number;
  downloaded: number;
  locked: number;
}

export interface Order {
  _id: string;
  name: string;
  skipQualityCheck?: boolean;
  lines?: number;
  orderLinesStatistic: OrderLinesStatistic;
  columns?: string[];
  agency: string;
  agency$?: Agency;
  createdAt: string;
  deletedAt?: string;
  createdBy?: string;
  createdBy$?: User;
  deletedBy?: string;
  deletedBy$?: User;
  state: ('New' | 'InProgress' | 'Finished');
  tags: Tag[];
  defaultContract?: string;
}

export interface OrderLineStateHistory {
  state: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred');
  date: string;
  user?: string;
  user$?: User;
  message?: string;
}

export interface OrderLineOrderLineTranslations {
  newValue: string;
  usedTranslation: string;
}

export interface OrderLine {
  _id: string;
  order: string;
  order$?: Order;
  agency: string;
  orderUpload?: string;
  orderParsing?: string;
  orderParsingChunk?: string;
  hash?: string;
  identifier: string;
  outputs: Output[];
  executionPlan?: StepHash[];
  lastJob?: string;
  lastJob$?: OrderLineJob;
  state: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred');
  stateHistory: OrderLineStateHistory[];
  assemblingUsedAssets?: AssemblingUsedAsset[];
  assemblingErrors?: AssemblingError[];
  plainOrderLine?: {[key: string]: string};
  orderLineTranslations?: {[key: string]: OrderLineOrderLineTranslations};
  createdBy: string;
  createdBy$?: User;
  createdAt: string;
  deletedAt?: string;
  deletedBy?: string;
  hasDuplicate?: boolean;
  locks?: string[];
}

export interface StateOrderLineHistoryEntry extends OrderLineHistoryEntry {
  type: ('StateOrderLineHistoryEntry');
  state: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred');
}

export interface OrderLineAggregation {
  state?: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred');
  plainOrderLine?: {[key: string]: string};
  statistic: OrderLinesStatistic;
}

export interface OrderLineStateOverview {
  waitingForPrerequisites: number;
  waitingForProcessing: number;
  processing: number;
  processed: number;
  waitingForQC: number;
  done: number;
  error: number;
}

export interface OrderLineUsedAsset {
  asset: string;
  assetVersion?: string;
}

export interface OrderLock {
  _id: string;
  order: string;
  name: string;
  filters: Condition[];
  identifiers: string[];
  deletedAt?: string;
  deletedBy?: string;
}

export interface BaseOrderParser {
  agency: string;
  name: string;
  identifierColumn: string;
  outputColumns: string[];
  assemblyTemplate?: AssemblyTemplate;
  assemblingTemplate: AssemblingTemplate;
}

export interface OrderParser extends BaseOrderParser {
  _id: string;
}

export interface OrderParsingResult {
  lines: number;
  skipped: number;
  successful: number;
  assetNotFound: number;
  assetVersionNotFound: number;
  cameraNotFound: number;
  invalidAssetType: number;
  invalidAssetVersionNumber: number;
  invalidCondition: number;
  invalidDefinitionType: number;
  invalidMainFile: number;
  missingCamera: number;
  missingScene: number;
  pathNotFound: number;
  variantSetNotFound: number;
  variantValueNotFound: number;
}

export interface OrderUploadFormat {
  type: ('CSV' | 'Excel');
}

export interface OrderParsingOrderParser {
  _id: string;
  name: string;
}

export interface OrderParsingStateHistory {
  state: ('Analyzing' | 'InProgress' | 'Finished' | 'Error');
  date: string;
}

export interface OrderParsing {
  _id: string;
  state: ('Analyzing' | 'InProgress' | 'Finished' | 'Error');
  stateHistory: OrderParsingStateHistory[];
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  chunks: number;
  finishedChunks: number;
  finishedAt?: string;
  error?: ('MissingColumns' | 'Unknown');
  order: string;
  orderParser: string;
  orderParser$?: OrderParsingOrderParser;
  duplicateMode: ('KeepBoth' | 'KeepNew' | 'KeepOld');
  defaultOutputFormat?: OutputFormat;
  ignoreOrderLineStates?: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred')[];
  orderUpload: string;
  orderParsingResult: OrderParsingResult;
  requiredColumns?: string[];
  columnsRequired: string[];
  columnsMissing: string[];
  columnsRead: string[];
  linesRead: number;
  identifierMissing: number;
  duplicatedIdentifier: number;
  table?: string;
  orderUploadFormat: OrderUploadFormat;
}

export interface OrderParsingChunk {
  _id: string;
  orderParsing: string;
  startedAt?: string;
  finishedAt?: string;
  error?: ('MissingColumns' | 'Unknown');
  errorMessage?: string;
  startingPosition: number;
  length: number;
  orderParsingResult: OrderParsingResult;
}

export interface OrderSubtaskSubtaskContext {
  
}

export interface OrderSubtask {
  asset: string;
  hash: string;
  numberOfOrderLines: number;
  subtask: string;
  subtaskType: ('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual');
  subtaskContext: OrderSubtaskSubtaskContext;
  subtaskState: ('New' | 'InProgress' | 'Done');
  task: string;
  taskState: ('New' | 'InProgress' | 'Done');
  assetType: ('material' | 'object' | 'scene' | 'prefab');
  assetName: string;
  assetDisplayName: string;
}

export interface OrderTranslationCondition {
  
}

export interface OrderTranslation {
  _id: string;
  order: string;
  condition?: OrderTranslationCondition;
  columns: string[];
  searchValue: string;
  replacementValue: string;
}

export interface OrderUpload {
  _id: string;
  order: string;
  order$?: Order;
  file?: string;
  file$?: File;
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  finishedAt?: string;
  state: ('InProgress' | 'Parsing' | 'Finished' | 'Uploading' | 'Error');
  orderParsings?: string[];
  orderParsings$?: OrderParsing[];
}

export interface OrderUploadFile extends File {
  type: ('OrderUploadFile');
  orderUpload: string;
}

export interface OrderUploadFormatCSV extends OrderUploadFormat {
  type: ('CSV');
  delimiter: string;
  textQualifier: string;
  skipRows: number;
}

export interface JpegImageOutputFormat extends OutputFormat {
  type: ('JpegImage');
  mimeType: string;
  extension: string;
}

export interface WebpImageOutputFormat extends OutputFormat {
  type: ('WebpImage');
  mimeType: string;
  extension: string;
}

export interface Package {
  asset: string;
  version: string;
  mainFile: string;
  dependencies: {[key: string]: string};
  devDependencies: {[key: string]: string};
  devFiles: string[];
  ignoreFiles?: string[];
  mainPrimPath?: string;
  compositingFile?: string;
  actions?: PrefabAction[];
}

export interface ArnoldCommand extends Command {
  name: string;
}

export interface ArnoldEngine extends Engine {
  type: ('Arnold');
  commands: ArnoldCommand[];
}

export interface HoudiniCommand extends Command {
  name: string;
}

export interface HoudiniEngine extends Engine {
  type: ('Houdini');
  commands: HoudiniCommand[];
}

export interface SourceTarget {
  source: string;
  target: string;
  length: number;
  mimeType: string;
  md5?: string;
}

export interface JobsStatistic {
  count: number;
  calculatedAt: string;
  queued: number;
  downloading: number;
  running: number;
  success: number;
  error: number;
  canceled: number;
}

export interface OrdersStatistic {
  count: number;
  calculatedAt: string;
  new: number;
  inProgress: number;
  finished: number;
}

export interface TasksStatistic {
  count: number;
  calculatedAt: string;
  new: number;
  inProgress: number;
  done: number;
}

export interface AgencyStatistic {
  _id: string;
  agency: string;
  calculatedAt: string;
  jobs: JobsStatistic;
  orders: OrdersStatistic;
  orderLines: OrderLinesStatistic;
  tasks: TasksStatistic;
  assetVersions: AssetVersionsStatistic;
  jobStatsPerDay?: {[key: string]: JobsStatistic};
}

export interface AgencyTags {
  agency: string;
  assetTags?: string[];
  taskTags?: string[];
  orderTags?: string[];
}

export interface SubtaskStateHistory {
  state: ('New' | 'InProgress' | 'Done');
  date: string;
  user: string;
}

export interface SubtaskAssigneeHistory {
  user: string;
  assignee?: string;
  date: string;
}

export interface SubtaskContractHistory {
  user: string;
  contract?: string;
  contractor?: string;
  date: string;
}

export interface SubtaskContext {
  
}

export interface Subtask {
  _id: string;
  type: ('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual');
  state: ('New' | 'InProgress' | 'Done');
  stateHistory: SubtaskStateHistory[];
  assignee?: string | null;
  assignee$?: User;
  assigneeHistory: SubtaskAssigneeHistory[];
  contract?: string | null;
  contract$?: Contract;
  contractHistory: SubtaskContractHistory[];
  contractor?: string | null;
  contractor$?: Agency;
  context: SubtaskContext;
  generated: boolean;
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  deletedAt?: string;
  deletedBy?: string;
  task?: string;
}

export interface AssetSubtaskOrderLine {
  order: string;
  identifier: string;
}

export interface AssetSubtask extends Subtask {
  type: ('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual');
  asset: string;
  asset$?: Asset;
  resolvedByAssetVersion?: string;
  numberOfOrderLines: number;
  orderLines?: AssetSubtaskOrderLine[];
}

export interface TaskStateHistory {
  state: ('New' | 'InProgress' | 'Done');
  date: string;
  user?: string;
}

export interface SubtasksStatistic {
  count: number;
  calculatedAt: string;
  new: number;
  inProgress: number;
  done: number;
}

export interface TaskSubtask {
  _id: string;
  state: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
  contractor?: string | null;
}

export interface Task {
  _id: string;
  type: ('AssetTask');
  name: string;
  description: string;
  state: ('New' | 'InProgress' | 'Done');
  stateHistory: TaskStateHistory[];
  subtasksStatistic: SubtasksStatistic;
  openSubtasks: TaskSubtask[];
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  agency: string;
  agency$?: Agency;
  assignees: string[];
  assignees$?: User[];
  contracts: string[];
  contracts$?: Contract[];
  contractors: string[];
  contractors$?: Agency[];
  deletedAt?: string;
  deletedBy?: string;
  tags: Tag[];
}

export interface AssetTask extends Task {
  _id: string;
  type: ('AssetTask');
  asset: string;
  asset$?: Asset;
  subtasks$?: AssetSubtask[];
}

export interface CreateAssetVersionSubtaskContext {
  assetVersionName: string;
}

export interface CreateAssetVersionSubtask extends AssetSubtask {
  type: ('CreateAssetVersion');
  context: CreateAssetVersionSubtaskContext;
}

export interface CreateCameraSubtaskContext {
  assetVersionName: string;
  camera: string;
}

export interface CreateCameraSubtask extends AssetSubtask {
  type: ('CreateCamera');
  context: CreateCameraSubtaskContext;
}

export interface CreateVariantSetSubtaskContext {
  assetVersionName: string;
  variantSet: string;
  path: string;
}

export interface CreateVariantSetSubtask extends AssetSubtask {
  type: ('CreateVariantSet');
  context: CreateVariantSetSubtaskContext;
}

export interface CreateVariantValueSubtaskContext {
  assetVersionName: string;
  variantSet: string;
  variantValue: string;
  path: string;
}

export interface CreateVariantValueSubtask extends AssetSubtask {
  type: ('CreateVariantValue');
  context: CreateVariantValueSubtaskContext;
}

export interface ManualSubtaskContext {
  id: string;
}

export interface ManualSubtask extends AssetSubtask {
  type: ('Manual');
  context: ManualSubtaskContext;
  name: string;
  description: string;
}

export interface SubtaskOrderLine {
  asset: string;
  type: ('CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual');
  hash: string;
  order: string;
  agency: string;
  identifier: string;
  orderParsing: string;
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
}

export interface TaskAttachmentFile extends File {
  type: ('TaskAttachmentFile');
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  task: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface Upload {
  _id: string;
  asset: string;
  user: string;
  agency: string;
  assetVersion: string;
  previousAssetVersion: string;
  state: ('Uploading' | 'Error' | 'Finished');
  package: Package;
  uploadedAt: string;
  errorAt: string;
  finishedAt: string;
  newFiles: string[];
  linkedFiles: string[];
}

export interface AbstractAuthUser {
  type: ('AuthUser' | 'ServiceAuthUser');
  _id: string;
  isActive: boolean;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface Group {
  type: ('CustomGroup' | 'ContractorGroup' | 'AgencyGroup');
  _id: string;
  name: string;
  description: string;
  users: string[];
  agency: string;
}

export interface CustomGroup extends Group {
  type: ('CustomGroup');
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  deletedBy?: string;
}

export interface ContractorGroup extends Group {
  type: ('ContractorGroup');
  contract: string;
  validTo: string;
}

export interface AgencyGroup extends Group {
  type: ('AgencyGroup');
}

export interface Invitation {
  _id: string;
  agency: string;
  agency$?: Agency;
  email: string;
  roles: string[];
  roles$?: Role[];
  user?: string;
  acceptedAt?: string;
  declinedAt?: string;
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  deletedAt?: string;
  deletedBy?: string;
  deletedBy$?: Agency;
}

export interface RBAC {
  _id: string;
  resource: string;
  user?: string;
  group?: string;
  roles: string[];
  roles$?: Role[];
  validTo?: string;
  createdAt: string;
  createdBy: string;
  createdBy$?: User;
  deletedAt?: string;
  deletedBy?: string;
  deletedBy$?: Agency;
}

export interface Right {
  _id: string;
  right: string;
  name: string;
  description: string;
}

export interface ServiceAuthUserApiKeys {
  md5: string;
  key: string;
  preview: string;
  expiresAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdAt: string;
  createdBy: string;
}

export interface ServiceAuthUser extends AbstractAuthUser {
  type: ('ServiceAuthUser');
  apiKeys: ServiceAuthUserApiKeys[];
  createdBy: string;
}

export interface ServiceUser extends AbstractUser {
  type: ('ServiceUser');
  agency: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export interface UserAgency {
  _id: string;
  user: string;
  user$?: User;
  agency: string;
  agency$?: Agency;
  deletedAt?: string;
  deletedBy?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  usdErrors: string[];
  error?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AgenciesJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AgenciesJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AgenciesJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface AgenciesJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface AgenciesJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface AgenciesJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AgenciesJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AgenciesJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AgenciesJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AgenciesJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AgenciesJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AgenciesJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AgenciesJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AgenciesJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AgenciesJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AgenciesJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AgenciesJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AgenciesJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AgenciesJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AgenciesJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AgenciesJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AgenciesJobsMailPostInvitationBody {
  invitation: string;
}

export interface AgenciesJobsMailPostVerificationBody {
  user: string;
}

export interface AgenciesJobsMailPostJobErrorBody {
  job: string;
}

export interface AgenciesJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AgenciesJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AgenciesJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AgenciesJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AgenciesJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AgenciesJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AgenciesJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AgenciesJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AgenciesJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AgenciesJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AgenciesMailPostInvitationBody {
  invitation: string;
}

export interface AgenciesMailPostVerificationBody {
  user: string;
}

export interface AgenciesMailPostJobErrorBody {
  job: string;
}

export interface AgenciesPostAgenciesBody {
  user: string;
  name: string;
  displayName: string;
}

export interface AgenciesPostAgencyContractsBody {
  user: string;
  agency: string;
  contractor: string;
  name: string;
  description: string;
  validTo: string;
}

export interface AgenciesPostAgencyGroupsBody {
  name: string;
  description: string;
  user: string;
}

export interface AgenciesPostAgencyInvitationsBody {
  email: string;
  roles: string[];
  user: string;
}

export interface AgenciesPostAgencyServiceUsersBody {
  displayName: string;
  user: string;
}

export interface AgenciesPostAgencyServiceUserBody {
  displayName: string;
  user: string;
}

export interface AgenciesPostAgencyServiceUserApiKeysBody {
  user: string;
}

export interface AgenciesPostAgencyContractAcceptBody {
  user: string;
}

export interface AgenciesPostAgencyContractDeclineBody {
  user: string;
}

export interface AgenciesPostAgencyGroupBody {
  name?: string;
  description?: string;
  roles?: string[];
  user: string;
}

export interface AgenciesPostAgencyInvitationBody {
  roles?: string[];
  user: string;
}

export interface AgenciesPostAgencyInvitationDeleteBody {
  user: string;
}

export interface AgenciesPostAgencyServiceUserDeleteBody {
  user: string;
}

export interface AgenciesPostAgencyServiceUserApiKeyDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  usdErrors: string[];
  error?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
  stepIndex: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  stepIndex?: number;
  error?: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: string;
  stepType: string;
  stepHash: string;
  jobOutput: string;
  jobProgress: number;
  jobOutputProgress: number;
  stepProgress: number;
}

export interface AssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: AssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface AssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface AssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface AssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface AssetsJobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
  stepIndex: number;
}

export interface AssetsJobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  stepIndex?: number;
  error?: string;
}

export interface AssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsJobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface AssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsJobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface AssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface AssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface AssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface AssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: AssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface AssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface AssetsJobsMailPostVerificationBody {
  user: string;
}

export interface AssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface AssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface AssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface AssetsJobsPostJobStartBody {
  renderer: string;
}

export interface AssetsJobsPostJobFinishedBody {
  renderer: string;
  error?: string;
}

export interface AssetsJobsPostJobDeferBody {
  renderer: string;
  nextStep: StepHash;
}

export interface AssetsJobsPostJobsStepStartBody {
  renderer: string;
  thread: number;
  agency: string;
  job: string;
}

export interface AssetsJobsPostJobsStepFinishedBody {
  renderer: string;
  thread: number;
  agency: string;
  error?: string;
}

export interface AssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
}

export interface AssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  error?: string;
  job?: string;
  nextStep?: StepHash;
}

export interface AssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface AssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface AssetsJobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface AssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface AssetsJobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress?: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface AssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface AssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface AssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: AssemblyTemplateVariant[];
}

export interface AssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface AssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface AssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface AssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface AssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface AssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface AssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface AssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface AssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface AssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: AssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface AssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface AssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface AssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface AssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface AssetsPostAssetAssetVersionAnalyzationResultBody {
  references: AssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: AssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface AssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface AssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface AssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface AssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface AssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface AssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface PostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('UploadPreview' | 'GeneratedPreview');
  title: string;
}

export interface PostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview extends PostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface PostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview extends PostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
  stepIndex: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  stepIndex?: number;
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: string;
  stepType: string;
  stepHash: string;
  jobOutput: string;
  jobProgress: number;
  jobOutputProgress: number;
  stepProgress: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface JobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface JobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface JobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface JobsAssetsJobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
  stepIndex: number;
}

export interface JobsAssetsJobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  stepIndex?: number;
  error?: string;
}

export interface JobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface JobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsJobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface JobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface JobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface JobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface JobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface JobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface JobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: JobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: JobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface JobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface JobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface JobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface JobsAssetsJobsPostJobStartBody {
  renderer: string;
}

export interface JobsAssetsJobsPostJobFinishedBody {
  renderer: string;
  error?: string;
}

export interface JobsAssetsJobsPostJobDeferBody {
  renderer: string;
  nextStep: StepHash;
}

export interface JobsAssetsJobsPostJobsStepStartBody {
  renderer: string;
  thread: number;
  agency: string;
  job: string;
}

export interface JobsAssetsJobsPostJobsStepFinishedBody {
  renderer: string;
  thread: number;
  agency: string;
  error?: string;
}

export interface JobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
}

export interface JobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  error?: string;
  job?: string;
  nextStep?: StepHash;
}

export interface JobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsAssetsJobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface JobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsAssetsJobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress?: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface JobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface JobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: AssemblyTemplateVariant[];
}

export interface JobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface JobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface JobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface JobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface JobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface JobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface JobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface JobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface JobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface JobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: JobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface JobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface JobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface JobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: JobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: JobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface JobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface JobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface JobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface JobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface JobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface JobsMailPostInvitationBody {
  invitation: string;
}

export interface JobsMailPostVerificationBody {
  user: string;
}

export interface JobsMailPostJobErrorBody {
  job: string;
}

export interface JobsPostJobsBody {
  user: string;
  agency: string;
  assemblings: Assembling[];
  pauseAt?: StepHash;
  clearCache?: boolean;
}

export interface JobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface JobsPostJobsGrepBodyCurrentSteps {
  stepType: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  hash: string;
  job: string;
}

export interface JobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: JobsPostJobsGrepBodyCurrentSteps[];
}

export interface JobsPostJobStartBody {
  renderer: string;
}

export interface JobsPostJobCancelBody {
  user: string;
}

export interface JobsPostJobPauseBody {
  stepHash?: StepHash;
  user: string;
}

export interface JobsPostJobContinueBody {
  user: string;
  pauseAt?: StepHash;
}

export interface JobsPostJobFinishedBody {
  renderer: string;
  error?: string;
}

export interface JobsPostJobDeferBody {
  renderer: string;
  nextStep: StepHash;
}

export interface JobsPostJobRestartBody {
  user: string;
  assemblings?: Assembling[];
  pauseAt?: StepHash;
  clearCache?: boolean;
}

export interface JobsPostJobsStepStartBody {
  renderer: string;
  thread: number;
  agency: string;
  job: string;
}

export interface JobsPostJobsStepFinishedBody {
  renderer: string;
  thread: number;
  agency: string;
  error?: string;
}

export interface JobsPostJobStepStartBody {
  renderer: string;
  thread: number;
}

export interface JobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  error?: string;
  job?: string;
  nextStep?: StepHash;
}

export interface JobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface JobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface JobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface JobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface JobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress?: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface OrdersTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  usdErrors: string[];
  error?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersAgenciesJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersAgenciesJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersAgenciesJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersAgenciesJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersAgenciesJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersAgenciesJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersAgenciesJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersAgenciesJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersAgenciesJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersAgenciesJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersAgenciesJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersAgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersAgenciesJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersAgenciesJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersAgenciesJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersAgenciesJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersAgenciesJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersAgenciesJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersAgenciesJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersAgenciesJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersAgenciesJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersAgenciesJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersAgenciesJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersAgenciesJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersAgenciesJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersAgenciesJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersAgenciesJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersAgenciesMailPostInvitationBody {
  invitation: string;
}

export interface OrdersAgenciesMailPostVerificationBody {
  user: string;
}

export interface OrdersAgenciesMailPostJobErrorBody {
  job: string;
}

export interface OrdersAgenciesPostAgencyBody {
  user: string;
  name: string;
  displayName: string;
}

export interface OrdersAgenciesPostAgencyContractsBody {
  user: string;
  agency: string;
  contractor: string;
  name: string;
  description: string;
  validTo: string;
}

export interface OrdersAgenciesPostAgencyGroupsBody {
  name: string;
  description: string;
  user: string;
}

export interface OrdersAgenciesPostAgencyInvitationsBody {
  email: string;
  roles: string[];
  user: string;
}

export interface OrdersAgenciesPostAgencyServiceUsersBody {
  displayName: string;
  user: string;
}

export interface OrdersAgenciesPostAgencyServiceUserBody {
  displayName: string;
  user: string;
}

export interface OrdersAgenciesPostAgencyServiceUserApiKeysBody {
  user: string;
}

export interface OrdersAgenciesPostAgencyContractAcceptBody {
  user: string;
}

export interface OrdersAgenciesPostAgencyContractDeclineBody {
  user: string;
}

export interface OrdersAgenciesPostAgencyGroupBody {
  name?: string;
  description?: string;
  roles?: string[];
  user: string;
}

export interface OrdersAgenciesPostAgencyInvitationBody {
  roles?: string[];
  user: string;
}

export interface OrdersAgenciesPostAgencyInvitationDeleteBody {
  user: string;
}

export interface OrdersAgenciesPostAgencyServiceUserDeleteBody {
  user: string;
}

export interface OrdersAgenciesPostAgencyServiceUserApiKeyDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
  stepIndex: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  stepIndex?: number;
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobProgressBody {
  step: string;
  stepType: string;
  stepHash: string;
  jobOutput: string;
  jobProgress: number;
  jobOutputProgress: number;
  stepProgress: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
  stepIndex: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  stepIndex?: number;
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface OrdersJobsAssetsJobsAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobStateBody {
  state: ('Queued' | 'Downloading' | 'Running' | 'Success' | 'Error' | 'Canceled');
  renderer: string;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobProgressBody {
  step: ('downloading' | 'running');
  percentage: number;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputStateBody {
  state: ('Queued' | 'Rendering' | 'Compositing' | 'Success' | 'Error' | 'Canceled');
  paths?: string[];
  code?: number;
  errorMessage?: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsJobsPostJobJobOutputProgressBody {
  step: ('rendering' | 'compositing');
  percentage: number;
  currentJobOutputIndex: number;
  totalJobOutputs: number;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: OutputTemplateVariant[];
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsUsdAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsAssetsUsdPostAnalyzeBody {
  assetVersion: AssetVersion;
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: OrdersJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersJobsAssetsJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsAssetsJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersJobsAssetsJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersJobsAssetsJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersJobsAssetsJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: StepHash[];
}

export interface OrdersJobsAssetsJobsPostJobStartBody {
  renderer: string;
}

export interface OrdersJobsAssetsJobsPostJobFinishedBody {
  renderer: string;
  error?: string;
}

export interface OrdersJobsAssetsJobsPostJobDeferBody {
  renderer: string;
  nextStep: StepHash;
}

export interface OrdersJobsAssetsJobsPostJobsStepStartBody {
  renderer: string;
  thread: number;
  agency: string;
  job: string;
}

export interface OrdersJobsAssetsJobsPostJobsStepFinishedBody {
  renderer: string;
  thread: number;
  agency: string;
  error?: string;
}

export interface OrdersJobsAssetsJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsAssetsJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  error?: string;
  job?: string;
  nextStep?: StepHash;
}

export interface OrdersJobsAssetsJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsAssetsJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsAssetsJobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface OrdersJobsAssetsJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsAssetsJobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress?: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface OrdersJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBody {
  type: ('GeneratedPreview' | 'UploadPreview' | 'OrderLinePreview');
  title: string;
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview {
  type: ('UploadPreview');
  length: number;
  mimeType: string;
  fileName: string;
  md5: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview {
  type: ('GeneratedPreview');
  previewTemplate: string;
  camera: string;
  variants: AssemblyTemplateVariant[];
}

export interface OrdersJobsAssetsTasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsTasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface OrdersJobsAssetsTasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsTasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersJobsAssetsTasksPostTasksAggregateBody {
  assets: string[];
}

export interface OrdersJobsAssetsTasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface OrdersJobsAssetsTasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsTasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface OrdersJobsAssetsPostAssetBody {
  displayName?: string;
  user: string;
  tags?: string[];
}

export interface OrdersJobsAssetsPostAssetsBody {
  name: string;
  assetType: string;
  agency: string;
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionsBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionPreviewSetsBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: OrdersJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionPreviewSetPreviewDeleteBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionPreviewSetProcessBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionPreviewSetPublishBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionDependenciesBody {
  dependencies: string[];
}

export interface OrdersJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences {
  path: string[];
  referencedPath: string[];
  referencedAssetVersion: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox {
  min: number[];
  max: number[];
}

export interface OrdersJobsAssetsPostAssetAssetVersionAnalyzationResultBody {
  references: OrdersJobsAssetsPostAssetAssetVersionAnalyzationResultBodyReferences[];
  variantSets: VariantSet[];
  materials: Material[];
  cameras: Camera[];
  targets: Target[];
  boundingBox?: OrdersJobsAssetsPostAssetAssetVersionAnalyzationResultBodyBoundingBox;
  usdErrors: string[];
  error?: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionDownloadsBody {
  forDev: boolean;
  internal?: boolean;
  onlyUsd?: boolean;
  includeDependencies?: boolean;
  onlyDependencies?: boolean;
  existingDependencies?: string[];
}

export interface OrdersJobsAssetsPostAssetAssetVersionPublishBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionRejectBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionSubmitBody {
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionRenderBody {
  md5: string;
  user: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionUploadErrorBody {
  errorTag: string;
}

export interface OrdersJobsAssetsPostAssetAssetVersionUploadsBody {
  user: string;
  package: Package;
  files: FileInfo[];
}

export interface OrdersJobsAssetsPostAssetCommentsBody {
  message: string;
  user: string;
  assetVersion?: string;
}

export interface OrdersJobsMailPostInvitationBody {
  invitation: string;
}

export interface OrdersJobsMailPostVerificationBody {
  user: string;
}

export interface OrdersJobsMailPostJobErrorBody {
  job: string;
}

export interface OrdersJobsPostJobsBody {
  user: string;
  agency: string;
  assemblings: Assembling[];
  pauseAt?: StepHash;
  clearCache?: boolean;
}

export interface OrdersJobsPostJobBody {
  user: string;
  tags?: string[];
}

export interface OrdersJobsPostJobsGrepBodyCurrentSteps {
  stepType: ('Basic_Or_v1' | 'Prefab_Houdini_19_5_534_Hython_v1' | 'Assembling_Default_v1' | 'Rendering_Houdini_19_5_534_Arnold_v1' | 'Compositing_Houdini_19_5_534_Hython_v1' | 'Utils_DownloadFile_v1' | 'Utils_ConvertImage_v1' | 'Utils_UsdAnalyzer_v1');
  hash: string;
  job: string;
}

export interface OrdersJobsPostJobsGrepBody {
  renderer: string;
  limit: number;
  currentJobs: string[];
  currentSteps: OrdersJobsPostJobsGrepBodyCurrentSteps[];
}

export interface OrdersJobsPostJobStartBody {
  renderer: string;
}

export interface OrdersJobsPostJobFinishedBody {
  renderer: string;
  error?: string;
}

export interface OrdersJobsPostJobDeferBody {
  renderer: string;
  nextStep: StepHash;
}

export interface OrdersJobsPostJobsStepStartBody {
  renderer: string;
  thread: number;
  agency: string;
  job: string;
}

export interface OrdersJobsPostJobsStepFinishedBody {
  renderer: string;
  thread: number;
  agency: string;
  error?: string;
}

export interface OrdersJobsPostJobStepStartBody {
  renderer: string;
  thread: number;
}

export interface OrdersJobsPostJobStepFinishedBody {
  renderer: string;
  thread: number;
  error?: string;
  job?: string;
  nextStep?: StepHash;
}

export interface OrdersJobsPostJobsAssetVersionBody {
  assetVersion: string;
  user: string;
}

export interface OrdersJobsPostJobsPreviewSetBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  user: string;
}

export interface OrdersJobsPostJobsUsdAnalyzerBody {
  asset: string;
  assetVersion: string;
}

export interface OrdersJobsPostJobsPreviewBody {
  asset: string;
  assetVersion: string;
  previewSet: string;
  preview: string;
  user: string;
}

export interface OrdersJobsPostJobProgressBody {
  stepType: string;
  stepHash: string;
  jobProgress: number;
  jobOutputProgress?: {[key: string]: number};
  stepProgress: number;
  stepIndex: number;
  totalSteps: number;
  preProcessingProgress: number;
  postProcessingProgress: number;
}

export interface OrdersPostOrderParsersBody {
  agency: string;
  name: string;
  identifierColumn: string;
  outputColumns: string[];
  assemblyTemplate: AssemblyTemplate;
}

export interface OrdersPostOrderBody {
  user: string;
  tags?: string[];
}

export interface OrdersPostOrdersBody {
  agency: string;
  user: string;
  name: string;
}

export interface OrdersPostOrderOrderLinesCompareBody {
  orderLines: string[];
}

export interface OrdersPostOrderOrderLinesDownloadBody {
  orderLines: string[];
}

export interface OrdersPostOrderOrderLinesLockBody {
  user: string;
  lockName: string;
  filter?: Condition;
  identifiers?: string[];
}

export interface OrdersPostOrderOrderUploadParseBody {
  user: string;
  orderParser?: string;
  newOrderParser?: BaseOrderParser;
  orderUploadFormat: OrderUploadFormat;
  duplicateMode: ('KeepBoth' | 'KeepNew' | 'KeepOld');
  ignoreOrderLineStates?: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred')[];
}

export interface OrdersPostOrderOrderUploadDebugBody {
  orderUploadFormat: OrderUploadFormat;
  orderParser: BaseOrderParser;
  pauseAt?: string;
  identifier?: string;
  output?: number;
  clearCache?: boolean;
}

export interface OrdersPostOrderOrderUploadAssemblyPreviewBody {
  orderUploadFormat: OrderUploadFormat;
  orderParser: BaseOrderParser;
  step: string;
  identifier?: string;
}

export interface OrdersPostOrderProcessBody {
  user: string;
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
}

export interface OrdersPostOrderReviewBody {
  user: string;
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
  ignoreBlockedVariantSelections?: boolean;
}

export interface OrdersPostOrderCancelBody {
  user: string;
  orderLines?: string[];
  filter?: Condition;
}

export interface OrdersPostOrderAcceptBody {
  user: string;
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
}

export interface OrdersPostOrderRejectBody {
  user: string;
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
}

export interface OrdersPostOrderLocksDeleteBody {
  user: string;
  lockIds: string[];
  restore: boolean;
}

export interface OrdersPostOrderOrderUploadPreviewBody {
  format: OrderUploadFormat;
  lines?: number;
}

export interface OrdersPostOrderOrderUploadsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface OrdersPostOrderOrderLineAcceptBody {
  user: string;
  next?: boolean;
}

export interface OrdersPostOrderOrderLineRejectBody {
  user: string;
  next?: boolean;
}

export interface OrdersPostOrderOrderLineReviewBody {
  user: string;
}

export interface OrdersPostOrderOrderLineProcessBody {
  user: string;
}

export interface OrdersPostOrderParsersValidateBody {
  orderParser: BaseOrderParser;
}

export interface OrdersPostOrderOrderLineDownloadedBody {
  user: string;
}

export interface OrdersPostOrderOrderLineCommentsBody {
  message: string;
  user: string;
}

export interface OrdersPostOrderDeleteBody {
  user: string;
}

export interface TasksPostTaskSubtasksBody {
  user: string;
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface TasksPostTaskCommentsBody {
  message: string;
  user: string;
}

export interface TasksPostTaskBody {
  user: string;
  tags?: string[];
}

export interface TasksPostTaskAttachmentsBody {
  user: string;
  length: number;
  mimeType: string;
  fileName: string;
}

export interface TasksPostTasksAggregateBody {
  assets: string[];
}

export interface TasksPostTaskStateBody {
  state: ('New' | 'InProgress' | 'Done');
  user: string;
}

export interface TasksPostTaskAttachmentDeleteBody {
  user: string;
}

export interface TasksPostTaskSubtaskBody {
  user: string;
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface AgenciesGetAgenciesQuery {
  assetType?: string;
}

export interface AgenciesGetAgencyJobsCountQuery {
  assetType?: string;
}

export interface AgenciesPostAgencyInvitationDeleteQuery {
  lookups?: string;
}

export interface AgenciesGetAgencyStatisticQuery {
  recalculate?: boolean;
}

export interface GetAgenciesQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetAgencyContractsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  valid?: boolean;
}

export interface GetAgencyInvitationsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetAgencyRolesQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetAgencyUsersQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface AssetsGetAssetsPreviewTemplateQuery {
  lookups?: string;
}

export interface AssetsGetAssetQuery {
  lookups?: string;
}

export interface AssetsGetAssetAssetVersionQuery {
  lookups?: string;
}

export interface AssetsGetAssetAssetVersionDependenciesQuery {
  includeDevDependencies?: boolean;
}

export interface AssetsGetAssetAssetVersionFilesQuery {
  path?: string;
  recursive?: boolean;
}

export interface GetAssetAssetVersionBlockedVariantSelectionsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetAssetAssetVersionHistoryQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetAssetAssetVersionPreviewSetsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetAssetAssetVersionVariantSetsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  types?: ('Local' | 'Referenced')[];
}

export interface GetAssetAssetVersionsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  state?: ('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete')[];
  agency?: string;
}

export interface GetAssetCommentsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetAssetHistoryQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  assetVersion?: string;
  states?: ('Empty' | 'Uploading' | 'Analyzing' | 'Error' | 'Finished' | 'Submitted' | 'Rejected' | 'Publishing' | 'Published' | 'Obsolete')[];
}

export interface GetAssetsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  publishedOnly?: boolean;
  assetType?: ('material' | 'object' | 'scene' | 'prefab')[];
  searchTerm?: string;
  agency?: string;
}

export interface GetAssetsPreviewTemplatesQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  assetType: ('material' | 'object' | 'scene' | 'prefab');
}

export interface JobsGetJobDownloadQuery {
  downloadType?: ('Output' | 'OutputZip' | 'Assembly' | 'AssemblyWithDependencies' | 'AssemblyWithUsdOnly');
  output?: number;
}

export interface JobsGetJobQuery {
  lookups?: string;
}

export interface JobsGetJobStepLogsQuery {
  skip?: number;
  limit?: number;
}

export interface GetJobsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  agency: string;
}

export interface GetJobsCountQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  agency: string;
}

export interface OrdersGetOrderQuery {
  lookups?: string;
}

export interface OrdersGetOrderOrderLineQuery {
  lookups?: string;
}

export interface OrdersGetOrderParserQuery {
  lookups?: string;
}

export interface OrdersGetOrderOrderUploadQuery {
  lookups?: string;
}

export interface OrdersPostOrderOrderLineAcceptQuery {
  lookups?: string;
}

export interface OrdersPostOrderOrderLineRejectQuery {
  lookups?: string;
}

export interface OrdersPostOrderOrderLineReviewQuery {
  lookups?: string;
}

export interface OrdersPostOrderOrderLineProcessQuery {
  lookups?: string;
}

export interface OrdersGetOrderOrderUploadOrderParsingQuery {
  lookups?: string;
}

export interface GetOrderOrderLineQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetOrderOrderLineCommentsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetOrderOrderLineJobsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetOrderOrderLinesQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  searchTerm?: string;
  states?: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred')[];
}

export interface GetOrderOrderLinesAggregationQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  searchTerm?: string;
  groupBy: string[];
  states?: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred')[];
}

export interface GetOrderOrderUploadOrderParsingsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetOrderOrderUploadsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetOrderParsersQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  agency: string;
}

export interface GetOrderParsersStepTemplatesQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetOrdersQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  agency: string;
  searchTerm?: string;
}

export interface TasksPostTaskSubtasksQuery {
  lookups?: string;
}

export interface TasksGetTaskQuery {
  lookups?: string;
}

export interface TasksPostTaskQuery {
  lookups?: string;
}

export interface TasksPostTaskAttachmentsQuery {
  lookups?: string;
}

export interface TasksPostTaskSubtaskQuery {
  lookups?: string;
}

export interface GetSubtasksQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetTaskAttachmentsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetTaskCommentsQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetTaskSubtasksQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  agency: string;
}

export interface GetTasksQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
  agency: string;
  searchTerm?: string;
}

export interface GetSubtasks200Response {
  items: Subtask[];
  totalCount: number;
}

export interface GetTasks200Response {
  items: Task[];
  totalCount: number;
}

export type GetTask200Response = Task | AssetTask

export interface GetTask404Response {
  errorTag: ('TASK_NOT_FOUND');
}

export interface GetTaskSubtasks200Response {
  items: Subtask[];
  totalCount: number;
}

export interface GetTaskComments200Response {
  items: Comment[];
  totalCount: number;
}

export interface GetTaskComments404Response {
  errorTag: ('TASK_NOT_FOUND');
}

export type PostTaskState200Response = Task | AssetTask

export interface PostTaskState404Response {
  errorTag: ('TASK_NOT_FOUND');
}

export type PostTaskComments200Response = Comment | AssetComment | OrderLineComment | TaskComment

export interface GetTaskAttachments200Response {
  items: TaskAttachmentFile[];
  totalCount: number;
}

export interface GetTaskAttachmentDownload200Response {
  downloadUrl: string;
}

export interface PostTaskAttachments200Response {
  signedURL: string;
  taskAttachment: TaskAttachmentFile;
}

export interface PostTaskAttachments404Response {
  errorTag: ('TASK_NOT_FOUND');
}

export interface PostTaskAttachmentDelete404Response {
  errorTag: ('TASKATTACHMENT_NOT_FOUND');
}

export interface PostTaskAttachmentFinished404Response {
  errorTag: ('TASK_NOT_FOUND' | 'TASKATTACHMENT_NOT_FOUND');
}

export interface PostTaskAttachmentError404Response {
  errorTag: ('TASK_NOT_FOUND' | 'TASKATTACHMENT_NOT_FOUND');
}

export type PostTaskSubtask200Response = Subtask | AssetSubtask

export interface PostTaskSubtask404Response {
  errorTag: ('TASK_NOT_FOUND' | 'SUBTASK_NOT_FOUND');
}

export interface PostTaskSubtask400Response {
  errorTag: ('INVALID_CONTRACT' | 'INVALID_ASSIGNEE');
}

export interface PostTaskSubtasks404Response {
  errorTag: ('TASK_NOT_FOUND');
}

export interface PostTaskSubtasks400Response {
  errorTag: ('INVALID_CONTRACT' | 'INVALID_ASSIGNEE');
}

export type PostTask200Response = Task | AssetTask

export interface PostTask404Response {
  errorTag: ('TASK_NOT_FOUND');
}

export interface GetAgencies200Response {
  items: Agency[];
  totalCount: number;
}

export interface GetAgencyUsers200Response {
  items: UserWithDetails[];
  totalCount: number;
}

export interface GetAgencyUsers404Response {
  errorTag: ('AGENCY_NOT_FOUND');
}

export interface GetAgencyStatistic404Response {
  errorTag: ('AGENCY_NOT_FOUND');
}

export interface GetAgencyStatistic401Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAgencies400Response {
  errorTag: ('AGENCY_ALREADY_EXISTS');
}

export interface GetAgencyContracts200Response {
  items: Contract[];
  totalCount: number;
}

export interface GetAgencyContracts404Response {
  errorTag: ('AGENCY_NOT_FOUND');
}

export interface GetAgencyServiceUser404Response {
  errorTag: ('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND');
}

export interface GetAgencyServiceUserApiKeys200ResponseItems {
  md5: string;
  key: string;
  preview: string;
  expiresAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdAt: string;
  createdBy: string;
}

export interface GetAgencyServiceUserApiKeys200Response {
  items: GetAgencyServiceUserApiKeys200ResponseItems[];
  totalCount: number;
}

export interface GetAgencyServiceUserApiKeys404Response {
  errorTag: ('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND');
}

export interface GetAgencyServiceUsers200Response {
  items: ServiceUser[];
  totalCount: number;
}

export interface GetAgencyServiceUsers404Response {
  errorTag: ('AGENCY_NOT_FOUND');
}

export interface GetAgencyInvitations200Response {
  items: Invitation[];
  totalCount: number;
}

export interface GetAgencyInvitations403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetAgencyRoles200Response {
  items: Role[];
  totalCount: number;
}

export interface GetAgencyRoles404Response {
  errorTag: ('AGENCY_NOT_FOUND');
}

export interface PostAgencyInvitations403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAgencyInvitationDelete403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAgencyServiceUser404Response {
  errorTag: ('AGENCY_NOT_FOUND' | 'AUTH_USER_NOT_FOUND' | 'USER_NOT_FOUND');
}

export interface PostAgencyServiceUserApiKeyDelete404Response {
  errorTag: ('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND' | 'API_KEY_NOT_FOUND');
}

export interface PostAgencyServiceUserApiKeys200ResponseServiceAuthUserApiKey {
  md5: string;
  key: string;
  preview: string;
  expiresAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdAt: string;
  createdBy: string;
}

export interface PostAgencyServiceUserApiKeys200Response {
  serviceAuthUserApiKey: PostAgencyServiceUserApiKeys200ResponseServiceAuthUserApiKey;
  apiKey: string;
}

export interface PostAgencyServiceUserApiKeys404Response {
  errorTag: ('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND' | 'API_KEY_NOT_FOUND');
}

export interface PostAgencyServiceUserDelete404Response {
  errorTag: ('AGENCY_NOT_FOUND' | 'USER_NOT_FOUND');
}

export interface PostAgencyServiceUsers404Response {
  errorTag: ('AGENCY_NOT_FOUND');
}

export interface GetJobs200Response {
  items: Job[];
  totalCount: number;
}

export interface GetJobs403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetJobStepLogs200Response {
  logEntries: LogEntry[];
}

export interface GetJobStepLogs403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetJobDownload200Response {
  downloadUrl?: string;
  downloads: SourceTarget[];
}

export interface GetJobDownload403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetJobJobOutputStepAssembly200Response {
  assembly: string;
}

export interface GetJobJobOutputStepAssembly403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetJobJobOutputStepOuputDownload404Response {
  errorTag: ('JOB_NOT_FOUND' | 'JOBOUTPUT_NOT_FOUND' | 'STEP_NOT_FOUND' | 'OUTPUT_NOT_FOUND');
}

export interface GetJobJobOutputStepOuputDownload400Response {
  errorTag: ('INVALID_FORMAT' | 'EXPIRED');
}

export interface GetJobJobOutputStepOuputDownload200Response {
  downloadUrl: string;
}

export interface GetJobJobOutputStepOuputDownload403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetJobsCount200Response {
  running: number;
  queued: number;
}

export interface GetJobsCount403Response {
  errorTag: ('FORBIDDEN');
}

export type GetJob200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface GetJob403Response {
  errorTag: ('FORBIDDEN');
}

export type PostJob200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJob404Response {
  errorTag: ('JOB_NOT_FOUND');
}

export type PostJobCancel200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJobCancel404Response {
  errorTag: ('JOB_NOT_FOUND');
}

export interface PostJobCancel400Response {
  errorTag: ('INVALID_STATE');
}

export type PostJobPause200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJobPause404Response {
  errorTag: ('JOB_NOT_FOUND');
}

export interface PostJobPause400Response {
  errorTag: ('INVALID_STATE');
}

export type PostJobContinue200Response = Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob

export interface PostJobContinue404Response {
  errorTag: ('JOB_NOT_FOUND');
}

export interface PostJobContinue400Response {
  errorTag: ('INVALID_STATE');
}

export interface GetUserHasRights200Response {
  rights: {[key: string]: boolean};
}

export interface GetUserHasRights403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetUserAgencies200Response {
  items: Agency[];
  totalCount: number;
}

export interface GetUserInvitations200Response {
  items: Invitation[];
  totalCount: number;
}

export interface GetUserInvitations403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostUserInvitationAccept404Response {
  errorTag: ('INVITATION_NOT_FOUND');
}

export interface PostUserInvitationAccept403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostUserInvitationDecline404Response {
  errorTag: ('INVITATION_NOT_FOUND');
}

export interface PostUserInvitationDecline403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetAssetsPreviewTemplates200Response {
  items: PreviewTemplate[];
  totalCount: number;
}

export interface GetAssetsPreviewTemplate404Response {
  errorTag: ('PREVIEWTEMPLATE_NOT_FOUND');
}

export interface GetAssets200Response {
  items: Asset[];
  totalCount: number;
}

export interface GetAssets403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetAsset404Response {
  errorTag: ('ASSET_NOT_FOUND');
}

export interface GetAssetAssetVersionHistory200Response {
  items: AssetHistoryEntry[];
  totalCount: number;
}

export interface GetAssetAssetVersionHistory404Response {
  errorTag: ('ASSET_NOT_FOUND');
}

export interface GetAssetHistory200Response {
  items: AssetHistoryEntry[];
  totalCount: number;
}

export interface GetAssetHistory404Response {
  errorTag: ('ASSET_NOT_FOUND');
}

export interface GetAssetAssetVersions200Response {
  items: AssetVersion[];
  totalCount: number;
}

export interface GetAssetAssetVersions404Response {
  errorTag: ('ASSET_NOT_FOUND');
}

export interface GetAssetAssetVersions400Response {
  errorTag: ('AGENCY_MISSING');
}

export interface GetAssetAssetVersions403Response {
  errorTag: ('FORBIDDEN');
}

export type GetAssetAssetVersion200Response = AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface GetAssetAssetVersion404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface GetAssetAssetVersionDownload200Response {
  downloadKey: string;
}

export interface GetAssetAssetVersionDownload400Response {
  errorTag: ('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID');
}

export interface GetAssetAssetVersionDownload404Response {
  errorTag: ('ASSETVERSION_NOT_FOUND');
}

export interface GetAssetAssetVersionDownloadKey200Response {
  errorTag: ('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID');
}

export interface GetAssetAssetVersionDownloadKey400Response {
  errorTag: ('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID');
}

export interface GetAssetAssetVersionDownloadKey404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'FILE_NOT_FOUND');
}

export interface GetAssetAssetVersionVariantSets200Response {
  items: AssetVersionVariantSet[];
  totalCount: number;
}

export interface GetAssetAssetVersionVariantSets404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface GetAssetAssetVersionPreviewSets200Response {
  items: PreviewSet[];
  totalCount: number;
}

export interface GetAssetAssetVersionPreviewSets404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface GetAssetAssetVersionPreviewSets403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetAssetAssetVersionBlockedVariantSelections200Response {
  items: BlockedVariantSelection[];
  totalCount: number;
}

export interface GetAssetAssetVersionBlockedVariantSelections404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface GetAssetAssetVersionFiles200ResponsePathFileMetadataItems {
  id: string;
  name: string;
  isFolder: boolean;
  path: string;
  size: number;
  finishedAt: string;
}

export interface GetAssetAssetVersionFiles200ResponsePathFileMetadata {
  items: GetAssetAssetVersionFiles200ResponsePathFileMetadataItems[];
}

export interface GetAssetAssetVersionFiles200Response {
  pathFileMetadata: {[key: string]: GetAssetAssetVersionFiles200ResponsePathFileMetadata};
  totalCount: number;
}

export interface GetAssetAssetVersionFiles400Response {
  errorTag: ('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID');
}

export interface GetAssetAssetVersionFile400Response {
  errorTag: ('MULTIPLE_ASSETS_FOUND' | 'INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID' | 'INVALID_FILE_ID');
}

export interface GetAssetAssetVersionFile404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface GetAssetAssetVersionFileDownload200Response {
  content?: string;
  url: string;
}

export interface GetAssetAssetVersionFileDownload400Response {
  errorTag: ('INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID' | 'INVALID_FILE_ID');
}

export interface GetAssetAssetVersionFileDownload404Response {
  errorTag: ('ASSETVERSION_FILE_NOT_FOUND');
}

export interface GetAssetAssetVersionFileHistory200ResponseItems {
  user: string;
  finishedAt: string;
  version: number;
  assetVersion: string;
  size: number;
}

export interface GetAssetAssetVersionFileHistory200Response {
  items: GetAssetAssetVersionFileHistory200ResponseItems[];
  totalCount: number;
}

export interface GetAssetAssetVersionFileHistory400Response {
  errorTag: ('MULTIPLE_ASSETS_FOUND' | 'INVALID_ASSET_ID' | 'INVALID_ASSETVERSION_ID' | 'INVALID_FILE_ID');
}

export interface GetAssetAssetVersionFileHistory404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'ASSETVERSION_FILE_NOT_FOUND');
}

export interface GetAssetComments200Response {
  items: Comment[];
  totalCount: number;
}

export interface GetAssetComments404Response {
  errorTag: ('ASSET_NOT_FOUND');
}

export type PostAssetAssetVersionSubmit200Response = AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface PostAssetAssetVersionSubmit404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface PostAssetAssetVersionSubmit403Response {
  errorTag: ('FORBIDDEN');
}

export type PostAssetAssetVersionPublish200Response = AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface PostAssetAssetVersionPublish404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface PostAssetAssetVersionPublish403Response {
  errorTag: ('FORBIDDEN');
}

export type PostAssetAssetVersionReject200Response = AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion

export interface PostAssetAssetVersionReject404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface PostAssetAssetVersionReject403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAssetAssetVersionBlockedVariantSelections200Response {
  successful: boolean;
}

export interface PostAssetAssetVersionBlockedVariantSelections404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface PostAssetAssetVersionBlockedVariantSelectionsDelete200Response {
  successful: boolean;
}

export interface PostAssetAssetVersionBlockedVariantSelectionsDelete404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export type PostAssetAssetVersionPreviewSetPreviews200Response = GeneratedPreview | OrderLinePreview | UploadPreview

export interface PostAssetAssetVersionPreviewSetPreviews404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND');
}

export interface PostAssetAssetVersionPreviewSetPreviews403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAssetAssetVersionPreviewSetPreviewError404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND' | 'PREVIEW_NOT_FOUND');
}

export interface PostAssetAssetVersionPreviewSetPreviewError403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAssetAssetVersionPreviewSetPreviewFinished404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND' | 'PREVIEW_NOT_FOUND');
}

export interface PostAssetAssetVersionPreviewSetPreviewFinished403Response {
  errorTag: ('FORBIDDEN');
}

export type PostAssetAssetVersionPreviewSetPreviewDelete200Response = GeneratedPreview | OrderLinePreview | UploadPreview

export interface PostAssetAssetVersionPreviewSetPreviewDelete404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND' | 'PREVIEW_NOT_FOUND');
}

export interface PostAssetAssetVersionPreviewSetPreviewDelete403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAssetAssetVersionPreviewSets404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND');
}

export interface PostAssetAssetVersionPreviewSets403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAssetAssetVersionPreviewSetProcess404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND');
}

export interface PostAssetAssetVersionPreviewSetProcess403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAssetAssetVersionPreviewSetPublish404Response {
  errorTag: ('ASSET_NOT_FOUND' | 'ASSETVERSION_NOT_FOUND' | 'PREVIEWSET_NOT_FOUND');
}

export interface PostAssetAssetVersionPreviewSetPublish403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostAsset404Response {
  errorTag: ('ASSET_NOT_FOUND');
}

export interface GetOrderParsersStepTemplates200Response {
  items: StepTemplate[];
  totalCount: number;
}

export interface GetOrderParsers200Response {
  items: OrderParser[];
  totalCount: number;
}

export interface GetOrderParsers404Response {
  errorTag: string;
}

export interface GetOrderParsers403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrder404Response {
  errorTag: string;
}

export interface GetOrder403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrders200Response {
  items: Order[];
  totalCount: number;
}

export interface GetOrderOrderLinesLocks200Response {
  items: OrderLock[];
  totalCount: number;
}

export interface GetOrderOrderLinesLocks403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderLinesDownloadKey200Response {
  errorTag: ('ORDER_NOT_FOUND' | 'FILE_NOT_FOUND');
}

export interface GetOrderOrderLinesAggregation200Response {
  items: OrderLineAggregation[];
  totalCount: number;
}

export interface GetOrderOrderLinesAggregation403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderLines200Response {
  items: OrderLine[];
  totalCount: number;
}

export interface GetOrderOrderLines404Response {
  errorTag: string;
}

export interface GetOrderOrderLines403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderSubtasks200Response {
  items: OrderSubtask[];
  totalCount: number;
}

export interface GetOrderSubtasks404Response {
  errorTag: string;
}

export interface GetOrderSubtasks403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderLine404Response {
  errorTag: string;
}

export interface GetOrderOrderLine403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderLineJobs200Response {
  items: OrderLineJob[];
  totalCount: number;
}

export interface GetOrderOrderLineJobs404Response {
  errorTag: string;
}

export interface GetOrderOrderLineJobs403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderLineOutputAssembly200Response {
  assembly: string;
}

export interface GetOrderOrderLineOutputAssembly403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderLineOutputAssembly404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND');
}

export interface GetOrderOrderUpload404Response {
  errorTag: string;
}

export interface GetOrderOrderUpload403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderUploads200Response {
  items: OrderUpload[];
  totalCount: number;
}

export interface GetOrderOrderUploads404Response {
  errorTag: string;
}

export interface GetOrderOrderUploads403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderParser404Response {
  errorTag: string;
}

export interface GetOrderParser403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderUploadOrderParsings200Response {
  items: OrderParsing[];
  totalCount: number;
}

export interface GetOrderOrderUploadOrderParsings404Response {
  errorTag: string;
}

export interface GetOrderOrderUploadOrderParsings403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderUploadOrderParsing404Response {
  errorTag: string;
}

export interface GetOrderOrderUploadOrderParsing403Response {
  errorTag: ('FORBIDDEN');
}

export interface GetOrderOrderLineComments200Response {
  items: Comment[];
  totalCount: number;
}

export interface GetOrderOrderLineComments404Response {
  errorTag: ('ORDERLINE_NOT_FOUND');
}

export interface GetOrderOrderLineHistory200Response {
  items: OrderLineHistoryEntry[];
  totalCount: number;
}

export interface GetOrderOrderLineHistory404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND');
}

export interface PostOrders400Response {
  errorTag: ('NAME_ALREADY_EXISTS' | 'INVALID_AGENCY');
}

export interface PostOrders403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderOrderLinesDownload200Response {
  downloadKey: string;
}

export interface PostOrderOrderLinesDownload404Response {
  errorTag: ('ORDER_NOT_FOUND');
}

export interface PostOrderOrderLinesCompare200ResponsePropertiesValues {
  sum: number;
}

export interface PostOrderOrderLinesCompare200ResponseProperties {
  values: {[key: string]: PostOrderOrderLinesCompare200ResponsePropertiesValues};
}

export interface PostOrderOrderLinesCompare200Response {
  properties: {[key: string]: PostOrderOrderLinesCompare200ResponseProperties};
}

export interface PostOrderOrderLinesCompare404Response {
  errorTag: ('ORDER_NOT_FOUND');
}

export interface PostOrderOrderLinesLock404Response {
  errorTag: ('ORDER_NOT_FOUND');
}

export interface PostOrderOrderUploads200Response {
  signedURL: string;
  orderUploadState: string;
  orderUploadId: string;
}

export interface PostOrderOrderUploads400Response {
  errorTag: ('INVALID_ORDERUPLOAD');
}

export interface PostOrderOrderUploads404Response {
  errorTag: ('ORDER_NOT_FOUND');
}

export interface PostOrderOrderUploads403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderOrderUploadDebug200Response {
  job?: Job;
  identifier: string;
  output: number;
  assemblingErrors?: AssemblingError[];
}

export interface PostOrderOrderUploadDebug404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND');
}

export interface PostOrderOrderUploadDebug400Response {
  errorTag: ('INVALID_OUTPUT');
}

export interface PostOrderOrderUploadAssemblyPreview200Response {
  plainOrderLine: {[key: string]: string};
  assemblies: string[];
}

export interface PostOrderOrderUploadAssemblyPreview404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND');
}

export interface PostOrderOrderUploadPreview200Response {
  plainOrderLines: {[key: string]: string}[];
  formatError?: string;
}

export interface PostOrderOrderUploadPreview404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND');
}

export interface PostOrderOrderUploadPreview403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderDelete404Response {
  errorTag: ('ORDER_NOT_FOUND');
}

export interface PostOrderDelete400Response {
  errorTag: ('USER_NOT_FOUND');
}

export interface PostOrderDelete403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderOrderUploadError400Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND');
}

export interface PostOrderOrderUploadError403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderOrderUploadFinished400Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND');
}

export interface PostOrderOrderUploadFinished403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderOrderUploadParse404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERUPLOAD_NOT_FOUND' | 'ORDERPARSER_NOT_FOUND');
}

export interface PostOrderOrderUploadParse403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderProcess200Response {
  affectedOrderLines: number;
}

export interface PostOrderProcess403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderAccept200Response {
  affectedOrderLines: number;
}

export interface PostOrderAccept403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderReject200Response {
  affectedOrderLines: number;
}

export interface PostOrderReject403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderReview200Response {
  affectedOrderLines: number;
}

export interface PostOrderReview403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderLocksDelete404Response {
  errorTag: ('USER_NOT_FOUND');
}

export type PostOrderOrderLineComments200Response = Comment | AssetComment | OrderLineComment | TaskComment

export interface PostOrderOrderLineComments403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrderOrderLineAccept404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND');
}

export interface PostOrderOrderLineAccept400Response {
  errorTag: ('INVALID_STATE');
}

export interface PostOrderOrderLineReject404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND');
}

export interface PostOrderOrderLineReject400Response {
  errorTag: ('INVALID_STATE');
}

export interface PostOrderOrderLineReview404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND');
}

export interface PostOrderOrderLineReview400Response {
  errorTag: ('INVALID_STATE');
}

export interface PostOrderOrderLineProcess404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND');
}

export interface PostOrderOrderLineProcess400Response {
  errorTag: ('INVALID_STATE');
}

export interface PostOrderOrderLineDownloaded404Response {
  errorTag: ('ORDER_NOT_FOUND' | 'ORDERLINE_NOT_FOUND');
}

export interface PostOrderOrderLineDownloaded403Response {
  errorTag: ('FORBIDDEN');
}

export interface PostOrder404Response {
  errorTag: ('ORDER_NOT_FOUND');
}

export interface GetTaskQuery {
  lookups?: string;
}

export interface PostTaskBody {
  tags?: string[];
}

export interface PostTaskSubtasksBody {
  name: string;
  description: string;
  assignee?: string | null;
  contract?: string | null;
}

export interface PostTaskSubtasksQuery {
  lookups?: string;
}

export interface PostTaskCommentsBody {
  message: string;
}

export interface PostTaskStateBody {
  state: string;
}

export interface PostTaskAttachmentsBody {
  length: number;
  mimeType: string;
  fileName: string;
}

export interface PostTaskAttachmentsQuery {
  lookups?: string;
}

export interface PostTaskSubtaskBody {
  state?: ('New' | 'InProgress' | 'Done');
  assignee?: string | null;
  contract?: string | null;
}

export interface PostTaskSubtaskQuery {
  lookups?: string;
}

export interface PostAgenciesBody {
  name: string;
  displayName: string;
}

export interface PostAgencyServiceUserBody {
  displayName: string;
}

export interface PostAgencyServiceUsersBody {
  displayName: string;
}

export interface PostAgencyInvitationsBody {
  email: string;
  roles: string[];
}

export interface PostAgencyInvitationDeleteQuery {
  lookups?: string;
}

export interface GetJobStepLogsQuery {
  skip?: number;
  limit?: number;
}

export interface PostJobBody {
  tags?: string[];
}

export interface PostJobPauseBody {
  stepHash?: StepHash;
}

export interface PostJobContinueBody {
  pauseAt?: StepHash;
}

export interface GetUserHasRightsQuery {
  resource: string;
  rights: string[];
}

export interface GetAssetQuery {
  lookups?: string;
}

export interface PostAssetBody {
  displayName?: string;
  tags?: string[];
}

export interface GetAssetAssetVersionQuery {
  lookups?: string;
}

export interface PostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections {
  variantSelections: VariantSelection[];
  reason?: string;
}

export interface PostAssetAssetVersionBlockedVariantSelectionsBody {
  blockedVariantSelections?: PostAssetAssetVersionBlockedVariantSelectionsBodyBlockedVariantSelections[];
  fromAssetVersion?: string;
}

export interface GetAssetAssetVersionFilesQuery {
  path?: string;
  recursive?: boolean;
}

export interface PostAssetCommentsBody {
  message: string;
  assetVersion?: string;
}

export interface PostAssetAssetVersionBlockedVariantSelectionsDeleteBody {
  blockedVariantSelections: string[];
}

export interface PostOrderParsersBody {
  name: string;
  agency: string;
  identifierColumn: string;
  outputColumns: string[];
  assemblyTemplate: AssemblyTemplate;
}

export interface GetOrderQuery {
  lookups?: string;
}

export interface PostOrderBody {
  tags?: string[];
}

export interface PostOrdersBody {
  agency: string;
  name: string;
}

export interface GetOrderSubtasksQuery {
  skip?: number;
  limit?: number;
  lookups?: string;
  orderBy?: string;
  orderDirection?: number;
  filter?: Condition;
}

export interface GetOrderOrderUploadQuery {
  lookups?: string;
}

export interface PostOrderOrderUploadsBody {
  length: number;
  mimeType: string;
  fileName: string;
}

export interface GetOrderParserQuery {
  lookups?: string;
}

export interface GetOrderOrderUploadOrderParsingQuery {
  lookups?: string;
}

export interface PostOrderOrderLineCommentsBody {
  message: string;
}

export interface PostOrderOrderLinesDownloadBody {
  orderLines: string[];
}

export interface PostOrderOrderLinesCompareBody {
  orderLines: string[];
}

export interface PostOrderOrderLinesLockBody {
  lockName: string;
  filter?: Condition;
  identifiers?: string[];
}

export interface PostOrderOrderUploadDebugBody {
  orderUploadFormat: OrderUploadFormat;
  orderParser: BaseOrderParser;
  restartJob?: string;
  pauseAt?: string;
  identifier?: string;
  output?: number;
  clearCache?: boolean;
}

export interface PostOrderOrderUploadAssemblyPreviewBody {
  orderUploadFormat: OrderUploadFormat;
  orderParser: BaseOrderParser;
  step: string;
  identifier?: string;
}

export interface PostOrderOrderUploadPreviewBody {
  format: OrderUploadFormat;
  lines?: number;
}

export interface PostOrderParsersValidateBody {
  orderParser: BaseOrderParser;
}

export interface PostOrderOrderUploadParseBody {
  orderParser?: string;
  newOrderParser?: BaseOrderParser;
  orderUploadFormat: OrderUploadFormat;
  defaultOutputFormat: OutputFormat;
  duplicateMode: ('KeepBoth' | 'KeepNew' | 'KeepOld');
  ignoreOrderLineStates?: ('WaitingForPrerequisites' | 'NoOutputsDefined' | 'Reviewing' | 'Downloaded' | 'PrerequisitesFulfilled' | 'WaitingForProcessing' | 'Queued' | 'Processing' | 'WaitingForQC' | 'Done' | 'Rejected' | 'Error' | 'Locked' | 'Deferred')[];
}

export interface PostOrderProcessBody {
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
}

export interface PostOrderAcceptBody {
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
}

export interface PostOrderRejectBody {
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
}

export interface PostOrderReviewBody {
  orderLines?: string[];
  filter?: Condition;
  searchTerm?: string;
  ignoreBlockedVariantSelections?: boolean;
}

export interface PostOrderLocksDeleteBody {
  lockIds: string[];
  restore: boolean;
}

export interface PostOrderOrderLineAcceptBody {
  next?: boolean;
}

export interface PostOrderOrderLineAcceptQuery {
  lookups?: string;
}

export interface PostOrderOrderLineRejectBody {
  next?: boolean;
}

export interface PostOrderOrderLineRejectQuery {
  lookups?: string;
}

export interface PostOrderOrderLineReviewQuery {
  lookups?: string;
}

export interface PostOrderOrderLineProcessQuery {
  lookups?: string;
}

abstract class AbstractService {

  protected queryParams(query?: any) : string {
    if (!query) return '';
    return '?' + Object.keys(query).filter(q => typeof query[q] !== 'undefined').map(q => {
      if (Array.isArray(query[q])) {
        return query[q].map((t: any) => `${q}=${encodeURIComponent(this.queryParam(t as any))}`).join('&')
      } else {
        return `${q}=${encodeURIComponent(this.queryParam(query[q] as any))}`
      }
    }).join('&');
  }

  protected queryParam(val: any) : string {
    if (typeof val === 'string') return val;
    if (typeof val === 'number') return val.toString();
    if (typeof val === 'boolean') return val ? 'true' : 'false';
    if (typeof val === 'object') return JSON.stringify(val);
    return '';
  }

}

@Injectable({
  providedIn: 'root'
})
export class TaskApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getSubtasks(query?: GetSubtasksQuery): Promise<[GetSubtasks200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetSubtasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetSubtasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getTasks(query?: GetTasksQuery): Promise<[GetTasks200Response | null, number, any]> {
    const url = `${this.basePath}/tasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getTask(taskId: string, query?: GetTaskQuery): Promise<[Task | AssetTask | null, number, GetTask404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Task | AssetTask>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Task | AssetTask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetTask404Response]
      }

      throw err
    }

  }

  public async postTask(taskId: string, body: PostTaskBody): Promise<[Task | AssetTask | null, number, PostTask404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Task | AssetTask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Task | AssetTask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTask404Response]
      }

      throw err
    }

  }

  public async getTaskSubtasks(taskId: string, query?: GetTaskSubtasksQuery): Promise<[GetTaskSubtasks200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskSubtasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskSubtasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskSubtasks(taskId: string, body: PostTaskSubtasksBody, query?: PostTaskSubtasksQuery): Promise<[ManualSubtask | null, number, PostTaskSubtasks400Response | PostTaskSubtasks404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ManualSubtask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ManualSubtask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostTaskSubtasks400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskSubtasks404Response]
      }

      throw err
    }

  }

  public async getTaskComments(taskId: string, query?: GetTaskCommentsQuery): Promise<[GetTaskComments200Response | null, number, GetTaskComments404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/comments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskComments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskComments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetTaskComments404Response]
      }

      throw err
    }

  }

  public async postTaskComments(taskId: string, body: PostTaskCommentsBody): Promise<[Comment | AssetComment | OrderLineComment | TaskComment | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/comments`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Comment | AssetComment | OrderLineComment | TaskComment>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Comment | AssetComment | OrderLineComment | TaskComment, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskState(taskId: string, body: PostTaskStateBody): Promise<[Task | AssetTask | null, number, PostTaskState404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/state`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Task | AssetTask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Task | AssetTask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskState404Response]
      }

      throw err
    }

  }

  public async getTaskAttachments(taskId: string, query?: GetTaskAttachmentsQuery): Promise<[GetTaskAttachments200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskAttachments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskAttachments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskAttachments(taskId: string, body: PostTaskAttachmentsBody, query?: PostTaskAttachmentsQuery): Promise<[PostTaskAttachments200Response | null, number, PostTaskAttachments404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostTaskAttachments200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostTaskAttachments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachments404Response]
      }

      throw err
    }

  }

  public async getTaskAttachmentDownload(taskId: string, taskAttachmentId: string): Promise<[GetTaskAttachmentDownload200Response | null, number, any]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${taskAttachmentId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetTaskAttachmentDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetTaskAttachmentDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postTaskAttachmentDelete(taskId: string, attachmentId: string): Promise<[TaskAttachmentFile | null, number, PostTaskAttachmentDelete404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${attachmentId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<TaskAttachmentFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as TaskAttachmentFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachmentDelete404Response]
      }

      throw err
    }

  }

  public async postTaskAttachmentFinished(taskId: string, taskAttachmentId: string): Promise<[TaskAttachmentFile | null, number, PostTaskAttachmentFinished404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${taskAttachmentId}/finished`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<TaskAttachmentFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as TaskAttachmentFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachmentFinished404Response]
      }

      throw err
    }

  }

  public async postTaskAttachmentError(taskId: string, taskAttachmentId: string): Promise<[TaskAttachmentFile | null, number, PostTaskAttachmentError404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/attachments/${taskAttachmentId}/error`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<TaskAttachmentFile>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as TaskAttachmentFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskAttachmentError404Response]
      }

      throw err
    }

  }

  public async postTaskSubtask(taskId: string, subtaskId: string, body: PostTaskSubtaskBody, query?: PostTaskSubtaskQuery): Promise<[Subtask | AssetSubtask | null, number, PostTaskSubtask400Response | PostTaskSubtask404Response | null]> {
    const url = `${this.basePath}/tasks/${taskId}/subtasks/${subtaskId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Subtask | AssetSubtask>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Subtask | AssetSubtask, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostTaskSubtask400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostTaskSubtask404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class AgencyApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getAgencies(query?: GetAgenciesQuery): Promise<[GetAgencies200Response | null, number, any]> {
    const url = `${this.basePath}/agencies`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencies200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencies200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencies(body: PostAgenciesBody): Promise<[Order | null, number, PostAgencies400Response | null]> {
    const url = `${this.basePath}/agencies`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostAgencies400Response]
      }

      throw err
    }

  }

  public async getAgencyUsers(agencyId: string, query?: GetAgencyUsersQuery): Promise<[GetAgencyUsers200Response | null, number, GetAgencyUsers404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/users`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyUsers200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyUsers200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyUsers404Response]
      }

      throw err
    }

  }

  public async getAgencyStatistic(agencyId: string): Promise<[AgencyStatistic | null, number, GetAgencyStatistic401Response | GetAgencyStatistic404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/statistic`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AgencyStatistic>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AgencyStatistic, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 401) return [null, response.status, response.error as unknown as GetAgencyStatistic401Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyStatistic404Response]
      }

      throw err
    }

  }

  public async getAgencyContracts(agencyId: string, query?: GetAgencyContractsQuery): Promise<[GetAgencyContracts200Response | null, number, GetAgencyContracts404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/contracts`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyContracts200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyContracts200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyContracts404Response]
      }

      throw err
    }

  }

  public async getAgencyServiceUser(agencyId: string, userId: string): Promise<[ServiceUser | null, number, GetAgencyServiceUser404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<ServiceUser>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyServiceUser404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUser(agencyId: string, userId: string, body: PostAgencyServiceUserBody): Promise<[ServiceUser | null, number, PostAgencyServiceUser404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceUser>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUser404Response]
      }

      throw err
    }

  }

  public async getAgencyServiceUserApiKeys(agencyId: string, userId: string): Promise<[GetAgencyServiceUserApiKeys200Response | null, number, GetAgencyServiceUserApiKeys404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/apikeys`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyServiceUserApiKeys200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyServiceUserApiKeys200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyServiceUserApiKeys404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUserApiKeys(agencyId: string, userId: string): Promise<[PostAgencyServiceUserApiKeys200Response | null, number, PostAgencyServiceUserApiKeys404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/apikeys`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAgencyServiceUserApiKeys200Response>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAgencyServiceUserApiKeys200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUserApiKeys404Response]
      }

      throw err
    }

  }

  public async getAgencyServiceUsers(agencyId: string): Promise<[GetAgencyServiceUsers200Response | null, number, GetAgencyServiceUsers404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyServiceUsers200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyServiceUsers200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyServiceUsers404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUsers(agencyId: string, body: PostAgencyServiceUsersBody): Promise<[ServiceUser | null, number, PostAgencyServiceUsers404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceUser>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUsers404Response]
      }

      throw err
    }

  }

  public async getAgencyInvitations(agencyId: string, query?: GetAgencyInvitationsQuery): Promise<[GetAgencyInvitations200Response | null, number, GetAgencyInvitations403Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/invitations`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyInvitations200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyInvitations200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetAgencyInvitations403Response]
      }

      throw err
    }

  }

  public async postAgencyInvitations(agencyId: string, body: PostAgencyInvitationsBody): Promise<[Invitation | null, number, PostAgencyInvitations403Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/invitations`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAgencyInvitations403Response]
      }

      throw err
    }

  }

  public async getAgencyRoles(agencyId: string, query?: GetAgencyRolesQuery): Promise<[GetAgencyRoles200Response | null, number, GetAgencyRoles404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/roles`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAgencyRoles200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAgencyRoles200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAgencyRoles404Response]
      }

      throw err
    }

  }

  public async getAgencyTags(agencyId: string): Promise<[AgencyTags | null, number, any]> {
    const url = `${this.basePath}/${agencyId}/tags`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AgencyTags>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AgencyTags, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAgencyInvitationDelete(agencyId: string, invitationId: string, query?: PostAgencyInvitationDeleteQuery): Promise<[Invitation | null, number, PostAgencyInvitationDelete403Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/invitations/${invitationId}/delete`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAgencyInvitationDelete403Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUserApiKeyDelete(agencyId: string, userId: string, apikeymd5: string): Promise<[ServiceAuthUser | null, number, PostAgencyServiceUserApiKeyDelete404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/apikeys/${apikeymd5}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceAuthUser>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceAuthUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUserApiKeyDelete404Response]
      }

      throw err
    }

  }

  public async postAgencyServiceUserDelete(agencyId: string, userId: string): Promise<[ServiceUser | null, number, PostAgencyServiceUserDelete404Response | null]> {
    const url = `${this.basePath}/agencies/${agencyId}/serviceusers/${userId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<ServiceUser>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as ServiceUser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAgencyServiceUserDelete404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class JobApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getJobs(query?: GetJobsQuery): Promise<[GetJobs200Response | null, number, GetJobs403Response | null]> {
    const url = `${this.basePath}/jobs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobs200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobs200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobs403Response]
      }

      throw err
    }

  }

  public async getJobStepLogs(jobId: string, stepIndexOrHash: string, query?: GetJobStepLogsQuery): Promise<[GetJobStepLogs200Response | null, number, GetJobStepLogs403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/steps/${stepIndexOrHash}/logs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobStepLogs200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobStepLogs200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobStepLogs403Response]
      }

      throw err
    }

  }

  public async getJobDownload(jobId: string): Promise<[GetJobDownload200Response | null, number, GetJobDownload403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobDownload403Response]
      }

      throw err
    }

  }

  public async getJobJobOutputStepAssembly(jobId: string, jobOutputId: string, stepId: string): Promise<[GetJobJobOutputStepAssembly200Response | null, number, GetJobJobOutputStepAssembly403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/joboutputs/${jobOutputId}/steps/${stepId}/assembly`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobJobOutputStepAssembly200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobJobOutputStepAssembly200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobJobOutputStepAssembly403Response]
      }

      throw err
    }

  }

  public async getJobJobOutputStepOuputDownload(jobId: string, jobOutputId: string, stepId: string, output: string): Promise<[GetJobJobOutputStepOuputDownload200Response | null, number, GetJobJobOutputStepOuputDownload400Response | GetJobJobOutputStepOuputDownload403Response | GetJobJobOutputStepOuputDownload404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/joboutputs/${jobOutputId}/steps/${stepId}/outputs/${output}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobJobOutputStepOuputDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobJobOutputStepOuputDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetJobJobOutputStepOuputDownload400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as GetJobJobOutputStepOuputDownload403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetJobJobOutputStepOuputDownload404Response]
      }

      throw err
    }

  }

  public async getJobsCount(query?: GetJobsCountQuery): Promise<[GetJobsCount200Response | null, number, GetJobsCount403Response | null]> {
    const url = `${this.basePath}/jobs/count`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetJobsCount200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetJobsCount200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJobsCount403Response]
      }

      throw err
    }

  }

  public async getJob(jobId: string, query?: JobsGetJobQuery): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, GetJob403Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetJob403Response]
      }

      throw err
    }

  }

  public async postJob(jobId: string, body: PostJobBody): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJob404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostJob404Response]
      }

      throw err
    }

  }

  public async postJobCancel(jobId: string): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJobCancel400Response | PostJobCancel404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/cancel`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostJobCancel400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostJobCancel404Response]
      }

      throw err
    }

  }

  public async postJobPause(jobId: string, body: PostJobPauseBody): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJobPause400Response | PostJobPause404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/pause`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostJobPause400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostJobPause404Response]
      }

      throw err
    }

  }

  public async postJobContinue(jobId: string, body: PostJobContinueBody): Promise<[Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob | null, number, PostJobContinue400Response | PostJobContinue404Response | null]> {
    const url = `${this.basePath}/jobs/${jobId}/continue`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Job | PreviewSetJob | OrderLineJob | PreviewJob | UsdAnalyzerJob, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostJobContinue400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostJobContinue404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getUserHasRights(userId: string, query?: GetUserHasRightsQuery): Promise<[GetUserHasRights200Response | null, number, GetUserHasRights403Response | null]> {
    const url = `${this.basePath}/users/${userId}/hasrights`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetUserHasRights200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetUserHasRights200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetUserHasRights403Response]
      }

      throw err
    }

  }

  public async getUserAgencies(userId: string): Promise<[GetUserAgencies200Response | null, number, any]> {
    const url = `${this.basePath}/users/${userId}/agencies`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetUserAgencies200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetUserAgencies200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getUserInvitations(userId: string): Promise<[GetUserInvitations200Response | null, number, GetUserInvitations403Response | null]> {
    const url = `${this.basePath}/users/${userId}/invitations`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetUserInvitations200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetUserInvitations200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetUserInvitations403Response]
      }

      throw err
    }

  }

  public async postUserInvitationAccept(userId: string, invitationId: string): Promise<[Invitation | null, number, PostUserInvitationAccept403Response | PostUserInvitationAccept404Response | null]> {
    const url = `${this.basePath}/${userId}/invitations/${invitationId}/accept`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostUserInvitationAccept403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostUserInvitationAccept404Response]
      }

      throw err
    }

  }

  public async postUserInvitationDecline(userId: string, invitationId: string): Promise<[Invitation | null, number, PostUserInvitationDecline403Response | PostUserInvitationDecline404Response | null]> {
    const url = `${this.basePath}/${userId}/invitations/${invitationId}/decline`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Invitation>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Invitation, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostUserInvitationDecline403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostUserInvitationDecline404Response]
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class AssetApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getAssetsPreviewTemplates(query?: GetAssetsPreviewTemplatesQuery): Promise<[GetAssetsPreviewTemplates200Response | null, number, any]> {
    const url = `${this.basePath}/assets/previewtemplates`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetsPreviewTemplates200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetsPreviewTemplates200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getAssetsPreviewTemplate(previewTemplateId: string, query?: AssetsGetAssetsPreviewTemplateQuery): Promise<[PreviewTemplate | null, number, GetAssetsPreviewTemplate404Response | null]> {
    const url = `${this.basePath}/assets/previewtemplates/${previewTemplateId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<PreviewTemplate>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewTemplate, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetsPreviewTemplate404Response]
      }

      throw err
    }

  }

  public async getAssets(query?: GetAssetsQuery): Promise<[GetAssets200Response | null, number, GetAssets403Response | null]> {
    const url = `${this.basePath}/assets`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssets200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssets200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetAssets403Response]
      }

      throw err
    }

  }

  public async getAsset(assetId: string, query?: GetAssetQuery): Promise<[Asset | null, number, GetAsset404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Asset>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Asset, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAsset404Response]
      }

      throw err
    }

  }

  public async postAsset(assetId: string, body: PostAssetBody): Promise<[Asset | null, number, PostAsset404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Asset>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Asset, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAsset404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionHistory(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionHistoryQuery): Promise<[GetAssetAssetVersionHistory200Response | null, number, GetAssetAssetVersionHistory404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/history`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionHistory404Response]
      }

      throw err
    }

  }

  public async getAssetHistory(assetId: string, query?: GetAssetHistoryQuery): Promise<[GetAssetHistory200Response | null, number, GetAssetHistory404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/history`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetHistory404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersions(assetId: string, query?: GetAssetAssetVersionsQuery): Promise<[GetAssetAssetVersions200Response | null, number, GetAssetAssetVersions400Response | GetAssetAssetVersions403Response | GetAssetAssetVersions404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersions200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersions200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersions400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as GetAssetAssetVersions403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersions404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersion(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionQuery): Promise<[AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, GetAssetAssetVersion404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersion404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionDownload(assetId: string, assetVersionId: string): Promise<[GetAssetAssetVersionDownload200Response | null, number, GetAssetAssetVersionDownload400Response | GetAssetAssetVersionDownload404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownload400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownload404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionDownloadKey(assetId: string, assetVersionId: string, downloadKey: string): Promise<[GetAssetAssetVersionDownloadKey200Response | null, number, GetAssetAssetVersionDownloadKey400Response | GetAssetAssetVersionDownloadKey404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/download/${downloadKey}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionDownloadKey200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionDownloadKey200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownloadKey400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionDownloadKey404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionVariantSets(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionVariantSetsQuery): Promise<[GetAssetAssetVersionVariantSets200Response | null, number, GetAssetAssetVersionVariantSets404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/variantsets`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionVariantSets200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionVariantSets200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionVariantSets404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionPreviewSets(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionPreviewSetsQuery): Promise<[GetAssetAssetVersionPreviewSets200Response | null, number, GetAssetAssetVersionPreviewSets403Response | GetAssetAssetVersionPreviewSets404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionPreviewSets200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionPreviewSets200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetAssetAssetVersionPreviewSets403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionPreviewSets404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSets(assetId: string, assetVersionId: string): Promise<[PreviewSet | null, number, PostAssetAssetVersionPreviewSets403Response | PostAssetAssetVersionPreviewSets404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PreviewSet>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewSet, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSets403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSets404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionBlockedVariantSelections(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionBlockedVariantSelectionsQuery): Promise<[GetAssetAssetVersionBlockedVariantSelections200Response | null, number, GetAssetAssetVersionBlockedVariantSelections404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/blockedvariantselections`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionBlockedVariantSelections200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionBlockedVariantSelections200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionBlockedVariantSelections404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionBlockedVariantSelections(assetId: string, assetVersionId: string, body: PostAssetAssetVersionBlockedVariantSelectionsBody): Promise<[PostAssetAssetVersionBlockedVariantSelections200Response | null, number, PostAssetAssetVersionBlockedVariantSelections404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/blockedvariantselections`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAssetAssetVersionBlockedVariantSelections200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAssetAssetVersionBlockedVariantSelections200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionBlockedVariantSelections404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFiles(assetId: string, assetVersionId: string, query?: GetAssetAssetVersionFilesQuery): Promise<[GetAssetAssetVersionFiles200Response | null, number, GetAssetAssetVersionFiles400Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionFiles200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionFiles200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFiles400Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFile(assetId: string, assetVersionId: string, fileId: string): Promise<[AssetVersionFile | null, number, GetAssetAssetVersionFile400Response | GetAssetAssetVersionFile404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files/${fileId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<AssetVersionFile>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersionFile, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFile400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionFile404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFileDownload(assetId: string, assetVersionId: string, fileId: string): Promise<[GetAssetAssetVersionFileDownload200Response | null, number, GetAssetAssetVersionFileDownload400Response | GetAssetAssetVersionFileDownload404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files/${fileId}/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionFileDownload200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionFileDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileDownload400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileDownload404Response]
      }

      throw err
    }

  }

  public async getAssetAssetVersionFileHistory(assetId: string, assetVersionId: string, fileId: string): Promise<[GetAssetAssetVersionFileHistory200Response | null, number, GetAssetAssetVersionFileHistory400Response | GetAssetAssetVersionFileHistory404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/files/${fileId}/history`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetAssetVersionFileHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetAssetVersionFileHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileHistory400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetAssetVersionFileHistory404Response]
      }

      throw err
    }

  }

  public async getAssetComments(assetId: string, query?: GetAssetCommentsQuery): Promise<[GetAssetComments200Response | null, number, GetAssetComments404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/comments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetAssetComments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetAssetComments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetAssetComments404Response]
      }

      throw err
    }

  }

  public async postAssetComments(assetId: string, body: PostAssetCommentsBody): Promise<[AssetComment | null, number, any]> {
    const url = `${this.basePath}/assets/${assetId}/comments`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetComment>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetComment, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postAssetAssetVersionSubmit(assetId: string, assetVersionId: string): Promise<[AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, PostAssetAssetVersionSubmit403Response | PostAssetAssetVersionSubmit404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/submit`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionSubmit403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionSubmit404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPublish(assetId: string, assetVersionId: string): Promise<[AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, PostAssetAssetVersionPublish403Response | PostAssetAssetVersionPublish404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/publish`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPublish403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPublish404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionReject(assetId: string, assetVersionId: string): Promise<[AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion | null, number, PostAssetAssetVersionReject403Response | PostAssetAssetVersionReject404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/reject`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as AssetVersion | MaterialAssetVersion | ObjectAssetVersion | PrefabAssetVersion | SceneAssetVersion, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionReject403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionReject404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionBlockedVariantSelectionsDelete(assetId: string, assetVersionId: string, body: PostAssetAssetVersionBlockedVariantSelectionsDeleteBody): Promise<[PostAssetAssetVersionBlockedVariantSelectionsDelete200Response | null, number, PostAssetAssetVersionBlockedVariantSelectionsDelete404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/blockedvariantselections/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostAssetAssetVersionBlockedVariantSelectionsDelete200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostAssetAssetVersionBlockedVariantSelectionsDelete200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionBlockedVariantSelectionsDelete404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviews(assetId: string, assetVersionId: string, previewSetId: string, body: PostAssetAssetVersionPreviewSetPreviewsBody): Promise<[GeneratedPreview | OrderLinePreview | UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviews403Response | PostAssetAssetVersionPreviewSetPreviews404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/previews`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GeneratedPreview | OrderLinePreview | UploadPreview>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GeneratedPreview | OrderLinePreview | UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviews403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviews404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviewError(assetId: string, assetVersionId: string, previewSetId: string, previewId: string): Promise<[UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviewError403Response | PostAssetAssetVersionPreviewSetPreviewError404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/preview/${previewId}/error`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<UploadPreview>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewError403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewError404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviewFinished(assetId: string, assetVersionId: string, previewSetId: string, previewId: string): Promise<[UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviewFinished403Response | PostAssetAssetVersionPreviewSetPreviewFinished404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/preview/${previewId}/finished`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<UploadPreview>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewFinished403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewFinished404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPreviewDelete(assetId: string, assetVersionId: string, previewSetId: string, previewId: string): Promise<[GeneratedPreview | OrderLinePreview | UploadPreview | null, number, PostAssetAssetVersionPreviewSetPreviewDelete403Response | PostAssetAssetVersionPreviewSetPreviewDelete404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/preview/${previewId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<GeneratedPreview | OrderLinePreview | UploadPreview>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GeneratedPreview | OrderLinePreview | UploadPreview, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewDelete403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPreviewDelete404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetProcess(assetId: string, assetVersionId: string, previewSetId: string): Promise<[PreviewSet | null, number, PostAssetAssetVersionPreviewSetProcess403Response | PostAssetAssetVersionPreviewSetProcess404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/process`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PreviewSet>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewSet, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetProcess403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetProcess404Response]
      }

      throw err
    }

  }

  public async postAssetAssetVersionPreviewSetPublish(assetId: string, assetVersionId: string, previewSetId: string): Promise<[PreviewSet | null, number, PostAssetAssetVersionPreviewSetPublish403Response | PostAssetAssetVersionPreviewSetPublish404Response | null]> {
    const url = `${this.basePath}/assets/${assetId}/assetversions/${assetVersionId}/previewsets/${previewSetId}/publish`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PreviewSet>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PreviewSet, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPublish403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostAssetAssetVersionPreviewSetPublish404Response]
      }

      throw err
    }

  }

  public async postAssetDelete(assetId: string): Promise<[Asset | null, number, any]> {
    const url = `${this.basePath}/assets/${assetId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Asset>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Asset, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

}

@Injectable({
  providedIn: 'root'
})
export class OrderApiService extends AbstractService {

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    super()
  }


  public async getOrderParsersStepTemplates(query?: GetOrderParsersStepTemplatesQuery): Promise<[GetOrderParsersStepTemplates200Response | null, number, any]> {
    const url = `${this.basePath}/orders/orderparsers/steptemplates`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderParsersStepTemplates200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderParsersStepTemplates200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getOrderParsers(query?: GetOrderParsersQuery): Promise<[GetOrderParsers200Response | null, number, GetOrderParsers403Response | GetOrderParsers404Response | null]> {
    const url = `${this.basePath}/orders/orderparsers`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderParsers200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderParsers200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderParsers403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderParsers404Response]
      }

      throw err
    }

  }

  public async postOrderParsers(body: PostOrderParsersBody): Promise<[OrderParser | null, number, any]> {
    const url = `${this.basePath}/orders/orderparsers`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderParser>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getOrder(orderId: string, query?: GetOrderQuery): Promise<[Order | null, number, GetOrder403Response | GetOrder404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<Order>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrder403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrder404Response]
      }

      throw err
    }

  }

  public async postOrder(orderId: string, body: PostOrderBody): Promise<[Order | null, number, PostOrder404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrder404Response]
      }

      throw err
    }

  }

  public async getOrders(query?: GetOrdersQuery): Promise<[GetOrders200Response | null, number, any]> {
    const url = `${this.basePath}/orders`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrders200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrders200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postOrders(body: PostOrdersBody): Promise<[Order | null, number, PostOrders400Response | PostOrders403Response | null]> {
    const url = `${this.basePath}/orders`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrders400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrders403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLinesLocks(orderId: string): Promise<[GetOrderOrderLinesLocks200Response | null, number, GetOrderOrderLinesLocks403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/locks`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLinesLocks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLinesLocks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLinesLocks403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLinesDownloadKey(orderId: string, downloadKey: string): Promise<[GetOrderOrderLinesDownloadKey200Response | null, number, any]> {
    const url = `${this.basePath}/${orderId}/orderlines/download/${downloadKey}`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLinesDownloadKey200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLinesDownloadKey200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async getOrderOrderLinesAggregation(orderId: string, query?: GetOrderOrderLinesAggregationQuery): Promise<[GetOrderOrderLinesAggregation200Response | null, number, GetOrderOrderLinesAggregation403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/aggregation`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLinesAggregation200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLinesAggregation200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLinesAggregation403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLines(orderId: string, query?: GetOrderOrderLinesQuery): Promise<[GetOrderOrderLines200Response | null, number, GetOrderOrderLines403Response | GetOrderOrderLines404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLines200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLines200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLines403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLines404Response]
      }

      throw err
    }

  }

  public async getOrderSubtasks(orderId: string, query?: GetOrderSubtasksQuery): Promise<[GetOrderSubtasks200Response | null, number, GetOrderSubtasks403Response | GetOrderSubtasks404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/subtasks`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderSubtasks200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderSubtasks200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderSubtasks403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderSubtasks404Response]
      }

      throw err
    }

  }

  public async getOrderOrderLine(orderId: string, orderLineId: string, query?: GetOrderOrderLineQuery): Promise<[OrderLine | null, number, GetOrderOrderLine403Response | GetOrderOrderLine404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderLine>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLine403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLine404Response]
      }

      throw err
    }

  }

  public async getOrderOrderLineJobs(orderId: string, orderLineId: string, query?: GetOrderOrderLineJobsQuery): Promise<[GetOrderOrderLineJobs200Response | null, number, GetOrderOrderLineJobs403Response | GetOrderOrderLineJobs404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/jobs`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLineJobs200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLineJobs200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLineJobs403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLineJobs404Response]
      }

      throw err
    }

  }

  public async getOrderOrderLineOutputAssembly(orderId: string, orderLineId: string, outputId: string): Promise<[GetOrderOrderLineOutputAssembly200Response | null, number, GetOrderOrderLineOutputAssembly403Response | GetOrderOrderLineOutputAssembly404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/outputs/${outputId}/assembly`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLineOutputAssembly200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLineOutputAssembly200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderLineOutputAssembly403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLineOutputAssembly404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUpload(orderId: string, orderuploadId: string, query?: GetOrderOrderUploadQuery): Promise<[OrderUpload | null, number, GetOrderOrderUpload403Response | GetOrderOrderUpload404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderuploadId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderUpload>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderUpload, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUpload403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUpload404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUploads(orderId: string, query?: GetOrderOrderUploadsQuery): Promise<[GetOrderOrderUploads200Response | null, number, GetOrderOrderUploads403Response | GetOrderOrderUploads404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderUploads200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderUploads200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUploads403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUploads404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploads(orderId: string, body: PostOrderOrderUploadsBody): Promise<[PostOrderOrderUploads200Response | null, number, PostOrderOrderUploads400Response | PostOrderOrderUploads403Response | PostOrderOrderUploads404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderUploads200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderUploads200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderUploads400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploads403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploads404Response]
      }

      throw err
    }

  }

  public async getOrderParser(orderparserId: string, query?: GetOrderParserQuery): Promise<[OrderParser | null, number, GetOrderParser403Response | GetOrderParser404Response | null]> {
    const url = `${this.basePath}/orders/orderparsers/${orderparserId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderParser>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParser, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderParser403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderParser404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUploadOrderParsings(orderId: string, orderUploadId: string, query?: GetOrderOrderUploadOrderParsingsQuery): Promise<[GetOrderOrderUploadOrderParsings200Response | null, number, GetOrderOrderUploadOrderParsings403Response | GetOrderOrderUploadOrderParsings404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/orderparsings`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderUploadOrderParsings200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderUploadOrderParsings200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsings403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsings404Response]
      }

      throw err
    }

  }

  public async getOrderOrderUploadOrderParsing(orderId: string, orderUploadId: string, orderParsingId: string, query?: GetOrderOrderUploadOrderParsingQuery): Promise<[OrderParsing | null, number, GetOrderOrderUploadOrderParsing403Response | GetOrderOrderUploadOrderParsing404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/orderparsings/${orderParsingId}`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<OrderParsing>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParsing, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsing403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderUploadOrderParsing404Response]
      }

      throw err
    }

  }

  public async getOrderOrderLineComments(orderId: string, orderLineId: string, query?: GetOrderOrderLineCommentsQuery): Promise<[GetOrderOrderLineComments200Response | null, number, GetOrderOrderLineComments404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/comments`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLineComments200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLineComments200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLineComments404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineComments(orderId: string, orderLineId: string, body: PostOrderOrderLineCommentsBody): Promise<[Comment | AssetComment | OrderLineComment | TaskComment | null, number, PostOrderOrderLineComments403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/comments`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Comment | AssetComment | OrderLineComment | TaskComment>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Comment | AssetComment | OrderLineComment | TaskComment, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderLineComments403Response]
      }

      throw err
    }

  }

  public async getOrderOrderLineHistory(orderId: string, orderLineId: string): Promise<[GetOrderOrderLineHistory200Response | null, number, GetOrderOrderLineHistory404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/history`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.get<GetOrderOrderLineHistory200Response>(completeUrl,  {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as GetOrderOrderLineHistory200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as GetOrderOrderLineHistory404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLinesDownload(orderId: string, body: PostOrderOrderLinesDownloadBody): Promise<[PostOrderOrderLinesDownload200Response | null, number, PostOrderOrderLinesDownload404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/download`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderLinesDownload200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderLinesDownload200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLinesDownload404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLinesCompare(orderId: string, body: PostOrderOrderLinesCompareBody): Promise<[PostOrderOrderLinesCompare200Response | null, number, PostOrderOrderLinesCompare404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/compare`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderLinesCompare200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderLinesCompare200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLinesCompare404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLinesLock(orderId: string, body: PostOrderOrderLinesLockBody): Promise<[OrderLock | null, number, PostOrderOrderLinesLock404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/lock`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLock>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLock, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLinesLock404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadDebug(orderId: string, orderUploadId: string, body: PostOrderOrderUploadDebugBody): Promise<[PostOrderOrderUploadDebug200Response | null, number, PostOrderOrderUploadDebug400Response | PostOrderOrderUploadDebug404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/debug`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderUploadDebug200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderUploadDebug200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderUploadDebug400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploadDebug404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadAssemblyPreview(orderId: string, orderUploadId: string, body: PostOrderOrderUploadAssemblyPreviewBody): Promise<[PostOrderOrderUploadAssemblyPreview200Response | null, number, PostOrderOrderUploadAssemblyPreview404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/assemblypreview`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderUploadAssemblyPreview200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderUploadAssemblyPreview200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploadAssemblyPreview404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadPreview(orderId: string, orderUploadId: string, body: PostOrderOrderUploadPreviewBody): Promise<[PostOrderOrderUploadPreview200Response | null, number, PostOrderOrderUploadPreview403Response | PostOrderOrderUploadPreview404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/preview`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderOrderUploadPreview200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderOrderUploadPreview200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadPreview403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploadPreview404Response]
      }

      throw err
    }

  }

  public async postOrderParsersValidate(body: PostOrderParsersValidateBody): Promise<[Assembling | null, number, any]> {
    const url = `${this.basePath}/orders/orderparsers/validate`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Assembling>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Assembling, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        
      }

      throw err
    }

  }

  public async postOrderDelete(orderId: string): Promise<[Order | null, number, PostOrderDelete400Response | PostOrderDelete403Response | PostOrderDelete404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<Order>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as Order, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderDelete400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderDelete403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderDelete404Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadError(orderId: string, orderUploadId: string): Promise<[OrderUpload | null, number, PostOrderOrderUploadError400Response | PostOrderOrderUploadError403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/error`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderUpload>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderUpload, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderUploadError400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadError403Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadFinished(orderId: string, orderUploadId: string): Promise<[OrderUpload | null, number, PostOrderOrderUploadFinished400Response | PostOrderOrderUploadFinished403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/finished`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderUpload>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderUpload, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderUploadFinished400Response]
      if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadFinished403Response]
      }

      throw err
    }

  }

  public async postOrderOrderUploadParse(orderId: string, orderUploadId: string, body: PostOrderOrderUploadParseBody): Promise<[OrderParsing | null, number, PostOrderOrderUploadParse403Response | PostOrderOrderUploadParse404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderuploads/${orderUploadId}/parse`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderParsing>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderParsing, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderUploadParse403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderUploadParse404Response]
      }

      throw err
    }

  }

  public async postOrderProcess(orderId: string, body: PostOrderProcessBody): Promise<[PostOrderProcess200Response | null, number, PostOrderProcess403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/process`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderProcess200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderProcess200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderProcess403Response]
      }

      throw err
    }

  }

  public async postOrderAccept(orderId: string, body: PostOrderAcceptBody): Promise<[PostOrderAccept200Response | null, number, PostOrderAccept403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/accept`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderAccept200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderAccept200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderAccept403Response]
      }

      throw err
    }

  }

  public async postOrderReject(orderId: string, body: PostOrderRejectBody): Promise<[PostOrderReject200Response | null, number, PostOrderReject403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/reject`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderReject200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderReject200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderReject403Response]
      }

      throw err
    }

  }

  public async postOrderReview(orderId: string, body: PostOrderReviewBody): Promise<[PostOrderReview200Response | null, number, PostOrderReview403Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/review`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<PostOrderReview200Response>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as PostOrderReview200Response, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderReview403Response]
      }

      throw err
    }

  }

  public async postOrderLocksDelete(orderId: string, body: PostOrderLocksDeleteBody): Promise<[OrderLock | null, number, PostOrderLocksDelete404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/locks/delete`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLock>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLock, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderLocksDelete404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineAccept(orderId: string, orderLineId: string, body: PostOrderOrderLineAcceptBody, query?: PostOrderOrderLineAcceptQuery): Promise<[OrderLine | null, number, PostOrderOrderLineAccept400Response | PostOrderOrderLineAccept404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/accept`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineAccept400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineAccept404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineReject(orderId: string, orderLineId: string, body: PostOrderOrderLineRejectBody, query?: PostOrderOrderLineRejectQuery): Promise<[OrderLine | null, number, PostOrderOrderLineReject400Response | PostOrderOrderLineReject404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/reject`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, body, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineReject400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineReject404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineReview(orderId: string, orderLineId: string, query?: PostOrderOrderLineReviewQuery): Promise<[OrderLine | null, number, PostOrderOrderLineReview400Response | PostOrderOrderLineReview404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/review`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineReview400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineReview404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineProcess(orderId: string, orderLineId: string, query?: PostOrderOrderLineProcessQuery): Promise<[OrderLine | null, number, PostOrderOrderLineProcess400Response | PostOrderOrderLineProcess404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/process`
    const completeUrl = `${url}${this.queryParams(query)}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 400) return [null, response.status, response.error as unknown as PostOrderOrderLineProcess400Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineProcess404Response]
      }

      throw err
    }

  }

  public async postOrderOrderLineDownloaded(orderId: string, orderLineId: string): Promise<[OrderLine | null, number, PostOrderOrderLineDownloaded403Response | PostOrderOrderLineDownloaded404Response | null]> {
    const url = `${this.basePath}/orders/${orderId}/orderlines/${orderLineId}/downloaded`
    const completeUrl = `${url}${""}`

    try {
      const token = await this.authService.accessToken()
      const response = await lastValueFrom(this.httpClient.post<OrderLine>(completeUrl, {}, {
        observe: 'response',
        responseType: <any>'json',
        headers: {
          'Accept': 'application/json',
          ...(token ? {'Authorization': 'Bearer ' + token} : {})
        }
      }))

      if (response.status >= 200 && response.status < 300) {
        return [response.body as OrderLine, response.status, null];
      }

      throw new Error('Unknown response: ' + response.status + ' - ' + response.body)

    } catch (err) {
      if ((err as any)?.name === 'HttpErrorResponse') {
        const response = err as HttpErrorResponse
        if (response.status === 403) return [null, response.status, response.error as unknown as PostOrderOrderLineDownloaded403Response]
      if (response.status === 404) return [null, response.status, response.error as unknown as PostOrderOrderLineDownloaded404Response]
      }

      throw err
    }

  }

}