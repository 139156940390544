import { Condition } from "projects/api/src/api";

export interface IQuery {
  [key: string] : string | number | boolean | undefined | string[] | object;
}

export interface IListQuery extends IQuery {
  limit?: number;
  skip?: number;
}

export interface ISortableListQuery extends IListQuery {
  orderBy?: string;
  orderDirection?: number;
}

export interface IFilterableListQuery extends ISortableListQuery {
  searchTerm?: string;
  filter?: Condition;
}

export interface IAggregationListQuery extends IFilterableListQuery {
  groupBy: string[]
}

export interface IResponse {}

export interface IListResponse<T> extends IResponse {
  items: T[];
  totalCount: number;
}

export type TableQueryResult<T> = IListResponse<T> | [IListResponse<T> | null, number, {errorTag: string} | null] | Promise<IListResponse<T>> | Promise<[IListResponse<T> | null, number, {errorTag: string} | null]>;

export type TableQuery<T> = {
  query: IFilterableListQuery;
  result?: TableQueryResult<T>;
}

export type TableAggregationQuery<A> = {
  query: IAggregationListQuery;
  result?: TableQueryResult<A>;
}

export type Action<T> = {
  label: string;
  icon?: string;
  action: (row: T) => Promise<void>
}

export type Filter = {
  type?: FilterType,
  path: string,
  conditionTypes?: ConditionType[],
  values?: {label: string, value: string}[] | Promise<{label: string, value: string}[]>
}

type linkable<T> = ({routerLink?: (row: T) => string[]} | {click?: (row: T) => void})

export type TableColumn<T> = {
  name: string,
  header?: string,
  style?: any,
  align?: 'left' | 'center' | 'right',
  sort?: string | boolean,
  visible?: 'fixed' | boolean,
  filter?: Filter,
  ellipsisMode?: 'default' | 'ellipsed',
  ellipsisWidth?: string,
  attr?: string,
} & ({
  format?: 'string',
  value?: (row: T) => string,
  icon?: (row: T) => string,
} & linkable<T> | {
  format: 'date',
  dateFormat?: string,
} & linkable<T> | {
  format: 'subtask',
} | {
  format: 'user',
} | {
  format: 'fileSize',
} | {
  format: 'tags',
} | {
  format: 'number',
  numberFormat?: string,
  value?: (row: T) => number,
} & linkable<T> | {
  component: (row: T) => {component: any, data: any, output?: any},
} | {
  actions: Action<T>[]
} | {
  dropdown: Action<T>[]
})

export type TableColumns<T> = TableColumn<T>[];

export type PredefinedFilter = {
  _id: string,
  name: string,
  filter: Condition,
  default?: boolean,
}

export type TileViewOptions<T> = {
  columns?: number,
  component: (row: T) => {component: any, data: any, output?: any},
}

export type TableAggregationOptions<A> = {
  sourceColumns: string[],
  columns: TableColumns<A>
}

export type TableButtonOptions = {
  action: () => void,
  label: string,
  icon?: string,
}

export type TableOptions<T, A = any> = {
  queryParamPrefix?: string,
  hideHeader?: boolean,
  size?: number,
  columns: TableColumns<T>,
  search?: boolean,
  defaultSort?: string,
  defaultSortDirection?: 'asc' | 'desc',
  searchPlaceholder?: string,
  routerLink?: (row: T) => string[],
  subRow?: (row: T) => {component: any, data: any},
  predefinedFilters?: PredefinedFilter[],
  hideColumnOptions?: boolean,
  hideIfEmpty?: boolean,
  tileView?: TileViewOptions<T>,
  selectable?: boolean,
  aggregation?: TableAggregationOptions<A>,
  additionalButtons?: TableButtonOptions[],
}

export type TableRefreshOptions = {
  showLoading?: boolean,
}

export const filterTypes = ['string'] as const //, 'boolean', 'objectid', 'date', 'number'] as const
export type FilterType = typeof filterTypes[number]

export const conditionTypes = [
  "And",
  "Or",
  "In",
  "NotIn",
  "StartsWith",
  "EndsWith",
  "Equals",
  "NotEquals",
  // "Exists",
  "Contains",
  "NotContains",
  "DateIs",
  "DateIsNot",
  "DateBefore",
  "DateAfter",
  // "GreaterThan",
  // "GreaterThanOrEqual",
  // "LessThan",
  // "LessThanOrEqual",
  // "EqualsObjectId",
  // "NotEqualsObjectId",
  // "InObjectId",
  // "NotInObjectId"
] as const;
export type ConditionType = typeof conditionTypes[number];

export const defaultConditionTypes: {[type: string]: ConditionType[]} = {
  'string': ['Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith', 'In', 'NotIn'],
  // 'boolean': ['Equals', 'NotEquals'],
  // 'objectid': ['EqualsObjectId', 'NotEqualsObjectId'],
  'date': ['DateIs', 'DateIsNot', 'DateAfter', 'DateBefore'],
  // 'number': ['Equals'],
}
