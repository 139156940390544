import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { AgencyService } from './services/agency.service';
import { UploadService } from './services/upload.service';
import mermaid from 'mermaid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading = true
  boxShadow = ''
  backgroundColor = ''
  rootPage = true
  noAgency = true

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    return !this.uploadService.uploading
  }

  get contentClass(): string {
    return this.rootPage || this.noAgency ? '' : 'padding'
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private agencyService: AgencyService,
    private uploadService: UploadService
  ) {
    this.calculateStyle()
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd && event.url) {
        this.rootPage = event.url === '/' || event.url.startsWith('/?') || event.url.startsWith('/#')
        // this.noAgency = false

        // if (this.authService.currentAuthUser && this.agencyService.currentUserAgencies.length === 0) {
        //   this.noAgency = true

        //   if (event.url !== '/manage/agencies') {
        //     this.router.navigate(['/manage/agencies'])
        //   }
        // }
      }
    });
  }

  async ngOnInit() {
    mermaid.initialize({
      securityLevel: 'loose',
      theme: 'dark'
    });

    this.authService.authUser.subscribe(async (authUser) => {
      this.loading = true

      if (authUser) {
        await this.agencyService.initUserAgencies(authUser._id)

        this.noAgency = !this.agencyService.currentSelectedAgency

        if (this.noAgency) {
          this.router.navigate(['/manage/agencies'])
        } else {
          await this.authService.navigateByState()
        }
      } else {
        this.noAgency = false
        await this.agencyService.resetUserAgencies()
      }

      // console.log('fg')
      // if (!authUser) {
      //   await this.agencyService.resetUserAgencies()
      // } else if (!this.agencyService.currentSelectedAgency) {
      //   await this.agencyService.initUserAgencies(authUser._id)
      // }

      setTimeout(() => {
        this.loading = false
      }, 1000)
    })

  }

  @HostListener('window:scroll') // for window scroll events
  calculateStyle() {
    const val = Math.min(1, window.pageYOffset / 75);

    this.boxShadow = `0px 0px ${val * 15}px 10px rgba(0,0,0,${0.75 * val})`
    this.backgroundColor = `rgba(26, 38, 54, ${val})`
  }
}
