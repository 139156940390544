import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  pastelColor(inputString: string, output: 'string', seeds?: {r?: number, g?: number, b?: number}): string
  pastelColor(inputString: string, output: 'object', seeds?: {r?: number, g?: number, b?: number}): {red: number, green: number, blue: number}
  pastelColor(inputString: string, output: 'object' | 'string', seeds?: {r?: number, g?: number, b?: number}): {red: number, green: number, blue: number} | string {

    //TODO: adjust base colour values below based on theme
    const baseRed = 128;
    const baseGreen = 128;
    const baseBlue = 128;

    //lazy seeded random hack to get values from 0 - 256
    //for seed just take bitwise XOR of first two chars
    let seed = inputString.charCodeAt(0) ^ inputString.charCodeAt(1);
    const rand_1 = Math.abs((Math.sin(seed++) * 10000)) % (seeds?.r || 256);
    const rand_2 = Math.abs((Math.sin(seed++) * 10000)) % (seeds?.g || 256);
    const rand_3 = Math.abs((Math.sin(seed++) * 10000)) % (seeds?.b || 256);

    //build colour
    const red = Math.round((rand_1 + baseRed) / 2);
    const green = Math.round((rand_2 + baseGreen) / 2);
    const blue = Math.round((rand_3 + baseBlue) / 2);

    if (output === 'object') {
      return { red: red, green: green, blue: blue };
    } else {
      return `rgb(${red}, ${green}, ${blue})`
    }
  }

  darkenColor(rgbColor: string, percentage: number) {
    // Parse the RGB color string and extract the values
    const match = rgbColor.match(/(\d+),\s*(\d+),\s*(\d+)/);
    if (!match) return rgbColor; // Invalid input
  
    const r = parseInt(match[1]);
    const g = parseInt(match[2]);
    const b = parseInt(match[3]);
  
    // Calculate the new color values
    const darkenFactor = 1 - percentage;
    const newR = Math.max(0, Math.floor(r * darkenFactor));
    const newG = Math.max(0, Math.floor(g * darkenFactor));
    const newB = Math.max(0, Math.floor(b * darkenFactor));
  
    // Return the new RGB color string
    return `rgb(${newR}, ${newG}, ${newB})`;
  }

  lightenColor(rgbColor: string, percentage: number) {
    // Parse the RGB color string and extract the values
    const match = rgbColor.match(/(\d+),\s*(\d+),\s*(\d+)/);
    if (!match) return rgbColor; // Invalid input
  
    const r = parseInt(match[1]);
    const g = parseInt(match[2]);
    const b = parseInt(match[3]);
  
    // Calculate the new color values
    const lightenFactor = 1 + percentage;
    const newR = Math.min(255, Math.floor(r * lightenFactor));
    const newG = Math.min(255, Math.floor(g * lightenFactor));
    const newB = Math.min(255, Math.floor(b * lightenFactor));
  
    // Return the new RGB color string
    return `rgb(${newR}, ${newG}, ${newB})`;
  }

  alphaColor(rgbColor: string, alpha: number) {
    // Parse the RGB color string and extract the values
    const match = rgbColor.match(/(\d+),\s*(\d+),\s*(\d+)/);
    if (!match) return rgbColor; // Invalid input
    
    // Return the new RGB color string
    return `rgb(${match[1]}, ${match[2]}, ${match[3]}, ${alpha})`;
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  lowerFirstLetter(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  wait(ms: number): Promise<void> {
    return new Promise<void>((resolve: () => void) => {
      setTimeout(resolve, ms)
    })
  }

  isMac(): boolean {
    return ((navigator as any).userAgentData?.platform || navigator.platform || '').toLowerCase().includes('mac')
  }
}
